import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import * as animationData from "assets/lottie/animation_onboarding_benefits.json";
import styled from "styled-components";
import ReviewCardSimple from "components/ReviewCardSimple";
import { getZodiacSign } from "helper/getZodiac";
import { BIRTHCHART_API, HOROSCOPE_EXPLANATIONS_API } from "constants/URLS";
import { getLanguage } from "helper/getLanguage";

const StyledGeneratingFirstStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .progress-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }

  .progress-circle {
    transform: rotate(-90deg);
  }

  .progress-background {
    fill: none;
    stroke: #e6e6e6;
    stroke-width: 3;
  }

  .progress-bar {
    fill: none;
    stroke: #c19c7b; /* Adjust this color for your theme */
    stroke-width: 3;
    stroke-linecap: round;
    transition: stroke-dasharray 0.3s ease;
  }

  .progress-text {
    font-family: "Polarity-Regular", serif;
    position: absolute;
    font-size: 32px;
    font-weight: 700;
    color: #c19c7b; /* Match with progress-bar color */
  }

  .info-box {
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    background: #f0f3fb;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    max-width: 400px;

    .info-box-intro {
    }

    .info-box-number {
      font-family: "Polarity-Regular", serif;
      color: #c19c7b;
      font-size: 30px;
      font-weight: 700;
      margin: 10px 0;
    }

    .info-box-text {
      font-size: 16px;
      line-height: 1.4;
    }
  }
`;

export default function GeneratingSecondStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  day,
  month,
  year,
  hours,
  minutes,
  location,
  gender,
  houseData,
  planetsData,
  setHousesData,
  setPlanetsData,
}) {
  const { t } = useTranslation("common");
  const [progress, setProgress] = useState(0);
  const [zodiac, setZodiac] = useState("gemini");
  const [birthchart, setBirthchart] = useState({});
  const [angleData, setAngleData] = useState([]);
  const [asteroidData, setAsteroidData] = useState([]);
  const [pointData, setPointData] = useState([]);
  const [mainAspectsData, setMainAspectsData] = useState([]);
  const [otherAspectsData, setOtherAspectsData] = useState([]);
  const [overviewCorePersonalities, setOverviewCorePersonalities] = useState(
    [],
  );
  const [overviewStellarComposition, setOverviewStellarComposition] = useState(
    [],
  );
  const [overviewLuarnodes, setOverviewLuarnodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getBirthchart();
  }, []);

  const getBirthchart = async () => {
    const cords = convertToDecimalCoordinates(location);

    let brithChartAPI = `${BIRTHCHART_API}?language=${getLanguage()}&day=${day}&month=${month}&year=${year}&minutes=${
      minutes || "00"
    }&hours=${hours || "00"}&lat=${cords.decimalLatitude}&lang=${cords.decimalLongitude}`;

    try {
      const response = await fetch(brithChartAPI);
      const data = await response.json();

      mapBirthChart(data.birthchart);
    } catch (error) {
      return [];
    }
  };

  function convertToDecimalCoordinates(location) {
    let latitudeDegrees = location.latitude_degrees;
    let latitudeMinutes = location.latitude_minutes;
    let longitudeDegrees = location.longitude_degrees;
    let longitudeMinutes = location.longitude_minutes;

    // Convert to decimal
    let decimalLatitude = latitudeDegrees + latitudeMinutes / 60;
    let decimalLongitude = longitudeDegrees + longitudeMinutes / 60;

    // Assuming the direction fields are not used because Berlin is in the NE quadrant
    // If you had proper direction indicators, you might need to adjust signs here

    return {
      decimalLatitude: decimalLatitude,
      decimalLongitude: decimalLongitude,
    };
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => Math.min(prev + 1, 100)); // Ensure progress doesn't exceed 100
    }, 100); // Increment progress every 50ms

    if (progress === 100) {
      const timeout = setTimeout(() => {
        scrollToWidget();
        setCurrentStep(currentStep + 1); // Move to the next step after a slight delay
      }, 1000); // Allow smooth transition
      return () => clearTimeout(timeout); // Cleanup timeout
    }

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [progress, currentStep, setCurrentStep, scrollToWidget]);

  function mapBirthChart(birthchart) {
    console.log("birthchart", birthchart);

    const aspectsArray = Object.values(birthchart.aspects).flatMap(
      Object.values,
    );

    aspectsArray.forEach((aspect) => {
      aspect.active = birthchart.objects[aspect.active];
      aspect.passive = birthchart.objects[aspect.passive];
    });

    // Convert houses to an array if it's not already
    const housesArray = Object.values(birthchart.houses);

    // Convert objects to an array
    let objectsArray = Object.values(birthchart.objects);

    objectsArray.forEach((obj) => {
      if (obj.name === "Asc") {
        obj.name = "Ascendant";
      }
      if (obj.name === "MC") {
        obj.name = "Medium Coeli";
      }
      if (obj.name === "IC") {
        obj.name = "Imum Coeli";
      }
      if (obj.name === "Desc") {
        obj.name = "Descendant";
      }
    });

    // Initialize an object to hold the combined data
    const combinedData = {};

    const planetsArray = objectsArray.filter(
      (obj) => obj.type.name === "Planet",
    );

    const asteroidArray = objectsArray.filter(
      (obj) => obj.type.name === "Asteroid",
    );
    const pointArray = objectsArray.filter((obj) => obj.type.name === "Point");

    const anglesArray = objectsArray.filter((obj) => obj.type.name === "Angle");

    objectsArray.forEach((obj) => {
      // Use the object's house number as the key
      const houseNumber = obj.house.number;

      // If there's no entry for this house number yet, initialize it
      if (!combinedData[houseNumber]) {
        combinedData[houseNumber] = {
          house: housesArray.find((house) => house.number === houseNumber),
          objects: [],
        };
      }

      // Add the object to the list for its house number
      combinedData[houseNumber].objects.push(obj);
    });

    /*const combinedDataArray = Object.keys(combinedData).map((key) => {
      const houseNumber = parseInt(key, 10); // Convert key back to number
      return {
        houseNumber,
        ...combinedData[houseNumber],
      };
    });*/

    let houseData = [];
    housesArray.forEach((house) => {
      let houseName = t(`${house.number}_house`);
      let sign = t(house.sign.name.toLowerCase());

      houseData.push({
        number: house.number,
        zodiac: house.sign.name.toLowerCase(),
        title: `${houseName}: ${sign}`,
        key: `${house.name}_${house.sign.name}`,
        text: t(`${house.name.replace(/ /g, "_").toLowerCase()}_text`),
        topic: encodeURIComponent(house.sign.name),
      });
    });

    let planetsData = [];
    planetsArray.forEach((planet) => {
      let planetName = t(`${planet.name.replace(/ /g, "_").toLowerCase()}`);
      let sign = t(planet.sign.name.toLowerCase());

      planetsData.push({
        title: `${planetName}: ${sign}`,
        key: `${planet.name} in ${planet.sign.name}`,
        text: t(`${planet.name.replace(/ /g, "_").toLowerCase()}_text`),
        topic: encodeURIComponent(planet.name),
      });
    });

    console.log("houseData", houseData);
    console.log("planetsData", planetsData);

    setBirthchart(birthchart);
    setHousesData(houseData);
    setPlanetsData(planetsData);

    setIsLoading(false);
  }

  const getExplenation = async (titles) => {
    try {
      const language = getLanguage();
      const requests = titles.map(async (title) => {
        const brithChartAPI = `${ONBOARDING_EXPLANATIONS_API}?key=${encodeURIComponent(
          title,
        )}&language=${language}`;
        const response = await fetch(brithChartAPI);
        return response.json();
      });
      const responses = await Promise.all(requests);
      const mergedArray = responses.reduce((acc, val) => acc.concat(val), []);
      return mergedArray;
    } catch (error) {
      console.error("Error fetching horoscope explanations:", error);
    }
  };

  useEffect(() => {
    const zodiacSign = getZodiacSign(day, month);
    setZodiac(zodiacSign);
  }, []);

  return (
    <StyledGeneratingFirstStep>
      <div className="progress-wrapper">
        <svg
          className="progress-circle"
          width="200"
          height="200"
          viewBox="0 0 36 36"
        >
          <path
            className="progress-background"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="progress-bar"
            strokeDasharray={`${progress}, 100`}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
        <div className="progress-text">{`${progress}%`}</div>
      </div>

      <span className="onboarding-headline">
        {t("welcome-page.step-generating-second-headline")}
      </span>

      <span className="onboarding-text">
        {t("welcome-page.step-time-text")}
      </span>

      <div className="onboarding-review-wrapper ">
        <ReviewCardSimple
          headline={t("welcome-page.step-generating-review-headline")}
          name={t("welcome-page.step-generating-review-name")}
          text={t("welcome-page.step-generating-review-text")}
        />
      </div>

      {/*<div className="info-box">
            <div className="info-box-intro">{t('welcome-page.we-already-helped')}</div>
            <div className="info-box-number">
              {t('welcome-page.a-combination-that-will-change-your-life')}
            </div>
            <div className="info-box-text">{t('welcome-page.discover-life-path')}</div>
      </div>*/}
    </StyledGeneratingFirstStep>
  );
}
