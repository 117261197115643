import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
`;

const Dialog = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
`;

const Headline = styled.h2`
  margin-bottom: 10px;
  color: #111;
  text-align: center;
  font-family: "Polarity-Regular", serif;
`;

const Message = styled.p`
  margin-bottom: 20px;
  line-height: 1.2;
  color: #111;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Button = styled.button`
  padding: 8px 14px;
  border: none;
  background: #c19c7b;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 1%;
  width: 100%;
  font-size: 14px;

  &:hover {
    background: #c19c7b;
  }
`;

const AlertDialog = ({ headline, message, onClose, onConfirm, onCancel }) => {
  const { t } = useTranslation("common");

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    onClose();
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  return ReactDOM.createPortal(
    <Overlay>
      <Dialog>
        <Headline>{headline}</Headline>
        <Message>{message}</Message>
        <ButtonContainer>
          <Button onClick={handleCancel}>{t("cancel")}</Button>
          <Button onClick={handleConfirm}>{t("continue")}</Button>
        </ButtonContainer>
      </Dialog>
    </Overlay>,
    document.body,
  );
};

export default AlertDialog;
