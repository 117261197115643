import styled from "styled-components";

export const StyledStoryPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .story-category {
    position: absolute;
    top: 12px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }

  .card-info-text {
    font-size: 14px;
    color: #7f8aa5;
    margin: 0 0 10px;
    text-align: center;

    @media only screen and (max-width: 375px) {
      font-size: 12px;
    }
  }

  .story-text {
    color: rgb(46, 59, 75);
    font-family: now, sans-serif;
    font-size: 16px;
    text-align: center;
    line-height: 1.4;
    padding: 5%;
    width: 100%;
    max-width: 740px;
    z-index: 99;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //margin-top: -5%;

    @media only screen and (max-width: 375px) {
      font-size: 14px;
    }

    .headline {
      font-family: "Polarity-Regular", serif;
      font-size: 14px;
      line-height: 1.2;
      font-weight: 700 !important;
      color: #111;
      color: #c19c7b;
      margin: 0 0 10px;
    }

    b {
      color: #c19c7b;
      font-family: "breathing", sans-serif;
      font-size: 30px;
      line-height: 1.2;
      position: relative;
      top: 10px;
      left: 4px;
      margin-bottom: 20px;
      display: block;

      @media only screen and (max-width: 375px) {
        font-size: 24px;
      }
    }

    h6 {
      font-size: 12px;
    }

    .tell-me-more {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 10px;
      font-size: 14px;
      display: inline-block;
      padding: 10px 20px;
      color: #7f8aa5;
      border-left: 1px solid #eee;
    }
  }

  .story-swipe-animation {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0.2;
    z-index: 999;
    pointer-events: none;
  }

  .card-text {
    font-size: 15px;
  }

  .card-lesson {
    line-height: 1.4;
    font-size: 18px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: "Polarity-Regular", serif;
    margin: 0 10% 6px;
    display: block;

    @media only screen and (max-width: 375px) {
      font-size: 12px;
    }
  }

  .card-keywords {
    margin: 10px;

    b {
      line-height: 1.4;
      font-size: 14px;
      font-weight: 400;
      padding: 0;
      margin: 0 0 10px;
      text-align: center;
      font-family: "Polarity-Regular", serif;
      display: block;
      color: #111;
      display: none;
    }

    span {
      padding: 3px 10px;
      color: #c19c7b;
      border-radius: 10px;
      font-size: 12px;
      line-height: 1;
      font-weight: 700;
      white-space: nowrap;
      border: 1px solid #fff;
      background-color: #fff;
      margin: 0 4px 6px;
      display: inline-block;
    }
  }

  .grid {
    font-size: 14px;
    padding: 0 3% 20px;

    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    div {
      flex: 50%;
    }

    b {
      line-height: 1.4;
      font-size: 12px;
      font-weight: 600;
      padding: 0;
      margin: 10px 0 0px;
      text-align: center;
      font-family: "Assistant", sans-serif;
      display: block;
      color: #111;
      left: 0;
      top: 0;
      position: static;

      @media only screen and (max-width: 375px) {
        font-size: 12px;
      }
    }

    span {
      display: inline-block;
      margin: 0;
      padding: 0 10px;
      color: #c19c7b;
      border-radius: 10px;
      font-family: "Polarity-Regular", serif;
      font-size: 15px;
      line-height: 1;
      font-weight: 700;

      @media only screen and (max-width: 375px) {
        font-size: 10px;
      }
    }
  }
`;
