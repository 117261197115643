import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { API_URL, ASSETS_URL, BASE_URL } from "constants/URLS";
import { useTranslation } from "react-i18next";
import { useAPI } from "context/APIcontext";
import styled from "styled-components";
import ContentCard from "./ContentCard";
import SectionHeader from "./SectionHeader";
import TagCloud from "./TagCloud";
import Modal from "./Modal";
import StoryPage from "pages/StoryPage/StoryPage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BlockButton from "./buttons/BlockButton";
import ChevronForwardIcon from "./SVG/ChevronForwardIcon";
import { fetchAstrologyEvents } from "pages/HomePage/components/AstrologyService";

const StyledAstroEvents = styled.div``;

const AstroEventCard = styled.div`
  position: relative;
  margin: 0;
  overflow: hidden;
  border: 1px solid whitesmoke;
  border-radius: 0;
  padding-bottom: 20px;
  margin: 20px 3% 20px;
  padding: 0 0 0 0;
  max-width: 536px;
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 10px 0 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;

  @media (min-width: 720px) {
    margin: 20px auto 20px;
  }

  .astro-card-inner {
    display: flex;

    cursor: pointer;

    .astro-card-image {
      width: 60px;
      min-width: 60px;
      min-height: 60px;
      height: 60px;
      //flex: 1;
      //align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      font-size: 24px;
      position: relative;
      margin-right: 0;
      font-family: "Polarity-Regular", serif;
      background-color: #fff;
      border: 1px solid whitesmoke;
      border-radius: 10px;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        /*background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 80%,
        rgba(255, 255, 255, 1) 100%
      );*/
      }
    }

    .astro-card-text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-left: 20px;
      width: 75%;
      line-height: 1.3;
      width: 100%;

      h4 {
        font-size: 14px;
        margin: 0 0 4px;
        color: #111;
        font-weight: 700;
        line-height: 1.2;
        width: 100%;
        font-family: Assistant, sans-serif;

        span {
          white-space: nowrap;
        }
      }

      p {
        margin: 0 0 0 0;
        font-size: 14px;
        color: #111;
        color: rgba(18, 18, 18, 0.75);
        font-family: Assistant, sans-serif;
        //color: #adadb3;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          white-space: nowrap;
        }
      }
    }

    .astro-card-icon {
      position: relative;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 40px;
      min-width: 40px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      align-items: center;
      right: 0;
      position: absolute;
      top: 16px;

      svg {
        width: 20px;
        margin: 0 auto;
        display: inline-block;
        fill: rgba(18, 18, 18, 0.75);
        color: rgba(18, 18, 18, 0.75);
      }
    }
  }

  .astro-card-events {
    .astro-card-event {
      display: flex;
      align-items: center;
      padding: 10px 0 0;
      margin: 10px 0 10px 0;
      border-top: 1px solid whitesmoke;
      cursor: pointer;

      .astro-card-event-date {
        min-width: 120px;
        color: rgba(18, 18, 18, 0.75);
        font-size: 13px;

        small {
          margin-top: 2px;
          display: block;
        }
      }
      .astro-card-event-text {
        font-size: 14px;
        width: 100%;

        small {
          margin-top: 2px;
          display: block;
          color: rgba(18, 18, 18, 0.75);
        }
      }

      .astro-event-tell-me-more {
        svg {
          width: 20px;
          height: 20px;
          fill: rgba(18, 18, 18, 0.75);
          color: rgba(18, 18, 18, 0.75);
        }
      }
    }
  }
`;

export default function AstrologyEvents({ date, mode = "day" }) {
  const { t } = useTranslation("common");
  const { getAstroEventExplaination } = useAPI();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [astroEventsData, setAstroEventsData] = useState([]);
  const [selectedAstroEvent, setSelectedAstroEvent] = useState(null);
  const [planetOptions] = useState([
    { label: t("all"), value: "all", icon: "📖" },
    { label: t("sun"), value: "sun", icon: "☀️" },
    { label: t("moon"), value: "moon", icon: "🌙" },
    { label: t("mercury"), value: "mercury", icon: "☿" },
    { label: t("venus"), value: "venus", icon: "♀" },
    { label: t("mars"), value: "mars", icon: "♂" },
    { label: t("jupiter"), value: "jupiter", icon: "♃" },
    { label: t("saturn"), value: "saturn", icon: "♄" },
    { label: t("uranus"), value: "uranus", icon: "⛢" },
    { label: t("neptune"), value: "neptune", icon: "♆" },
    { label: t("pluto"), value: "pluto", icon: "♇" },
  ]);
  const [selectedPlanet, setSelectedPlanet] = useState(planetOptions[0]);

  useEffect(() => {
    const loadAstrologyEvents = async () => {
      try {
        setLoading(true);
        const events = await fetchAstrologyEvents(date);
        setAstroEventsData(events);
        console.log(events);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadAstrologyEvents();
  }, [date]);

  const getAstroEvent = async (astroEvent) => {
    const explanation = await getAstroEventExplaination(astroEvent);
    setSelectedAstroEvent(explanation);
  };

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div></div>;
  }

  // Filter events based on selected planet - only filter by firstPlanet
  const getFilteredEvents = () => {
    return astroEventsData.filter(
      (event) =>
        selectedPlanet.value === "all" ||
        event.firstPlanet.toLowerCase() === selectedPlanet.value.toLowerCase(),
    );
  };

  return (
    <StyledAstroEvents>
      <div className="animated fadeIn">
        <SectionHeader
          title={t("astro-events.headline")}
          text={t("astro-events.subheadline")}
        />
      </div>

      <TagCloud
        items={planetOptions}
        selectedItem={selectedPlanet}
        onClick={setSelectedPlanet}
        propertyName="label"
      />

      {getFilteredEvents().map((astroEvent, index) => (
        <ContentCard
          key={`astroEvent${index}`}
          circleText={`${dayjs(astroEvent.date).format("DD")}<br />${t(
            dayjs(astroEvent.date).format("MMMM").toLowerCase(),
          ).slice(0, 3)}`}
          title={`${t(astroEvent.firstPlanet)} ${t(astroEvent.event)} ${t(
            astroEvent.secondPlanet,
          )}`}
          onClick={() => {
            getAstroEvent(
              `${astroEvent.firstPlanet} ${astroEvent.aspect} ${
                astroEvent.secondPlanet || ""
              } ${astroEvent.isRetrograde ? ",retrograde" : ""}`,
            );
          }}
        />
      ))}

      {selectedAstroEvent && (
        <Modal onClose={() => setSelectedAstroEvent(null)}>
          <StoryPage
            title={selectedAstroEvent[0].title}
            text={selectedAstroEvent[0].text}
            handleClose={() => setSelectedAstroEvent(null)}
          />
        </Modal>
      )}
    </StyledAstroEvents>
  );
}
