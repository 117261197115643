import dayjs from "dayjs";

export const fetchAstrologyEvents = async (date) => {
  const formattedDate = dayjs(date).format("YYYY-MM-DD");
  const response = await fetch(
    `https://tinyaffirmation.com/api/astro-events/astro-events-timeframe.php?date=${formattedDate}&timeframe=month`,
  );
  const data = await response.json();
  return data;
};

export const transformAstrologyEventsToTasks = (events, selectedDate) => {
  if (!events || !Array.isArray(events)) return [];

  const today = dayjs(selectedDate);
  const nextWeek = today.add(7, "day");
  const nextMonth = today.add(30, "day");
  const nextYear = today.add(365, "day");

  // Group events by timeframe
  const groupedEvents = events.reduce(
    (acc, event) => {
      const eventDate = dayjs(event.date);

      if (eventDate.isBefore(nextWeek)) {
        acc.thisWeek.push(event);
      } else if (eventDate.isBefore(nextMonth)) {
        acc.thisMonth.push(event);
      } else if (eventDate.isBefore(nextYear)) {
        acc.thisYear.push(event);
      }

      return acc;
    },
    { thisWeek: [], thisMonth: [], thisYear: [] },
  );

  // Transform events to tasks
  const transformEvent = (event, timeframe) => ({
    title: `${event.firstPlanet} ${event.event} ${event.secondPlanet || ""}`,
    intro: `${dayjs(event.date).format("DD.MM.YYYY HH:mm")} - ${event.zodiac} ${event.angle}`,
    topic: "astrology",
    isDone: false,
    isAstrologyEvent: true,
    timeframe,
    originalEvent: event,
  });

  return {
    weeklyEvents: groupedEvents.thisWeek.map((event) =>
      transformEvent(event, "week"),
    ),
    monthlyEvents: groupedEvents.thisMonth.map((event) =>
      transformEvent(event, "month"),
    ),
    yearlyEvents: groupedEvents.thisYear.map((event) =>
      transformEvent(event, "year"),
    ),
  };
};
