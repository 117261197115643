import React, { useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import Lottie from "react-lottie";
import * as animationDataOriginal from "assets/lottie/animation_onboarding_benefits.json";
import styled from "styled-components";

const StyledLoadingState = styled.div`
  margin: 30px 0 100px;
  text-align: center;
  padding-top: 30px;
  width: 100%;

  &.no-margin {
    margin: 30px 0 0;
  }

  @media (min-width: 720px) {
    margin: 30px auto 100px;
  }

  &.is-full-height {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    max-width: 580px;
    margin: 0 auto;
  }

  .loading-animation {
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
  }

  h2 {
    font-family: "Polarity-Regular", serif;
    color: #111;
    max-width: 100%;
    margin: 0 10% 10px;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
  }

  span {
    display: block;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.6;
    color: #111;
    max-width: 300px;
  }
`;

export default function LoadingState({
  title,
  text,
  animationData = animationDataOriginal,
  noMargin = false,
}) {
  const { t } = useTranslation("common");

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });

  return (
    <StyledLoadingState className={`${noMargin ? "no-margin" : ""}`}>
      <div className="loading-animation">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>
      <h2>{title}</h2>
      <span>{text}</span>
    </StyledLoadingState>
  );
}
