import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PageTitle from "components/PageTitle";
import { API_URL, ARTICLES_DATA_API, SOUNDS_DATA_API } from "constants/URLS";
import Modal from "components/Modal";
import { getLanguage } from "helper/getLanguage";
import StoryPage from "pages/StoryPage/StoryPage";
import { useAPI } from "context/APIcontext";
import LockClosedIcon from "components/SVG/LockClosedIcon";
import ContentCard from "components/ContentCard";
import SectionHeader from "components/SectionHeader";
import WisdomListPage from "pages/WisdomPage/WisdomListPage";
import { shuffleArray } from "./../../helper/shuffleArray";
import ContentCardBig from "components/ContentCardBig";
import LandingPage from "pages/LandingPage/LandingPage";
import StyledSlider from "components/StyledSlider";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";
import CoachingSessions from "./CoachingSessions";
import * as animationData from "assets/lottie/animation_onboarding_name.json";
import Lottie from "react-lottie";

const StyledWisdomPage = styled.div`
  max-width: 580px;
  margin: 0;
  width: 100%;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }
`;

export default function WisdomPage() {
  const { t } = useTranslation("common");

  const { isPremium, coachingSessions } = useAPI();

  const [selectedView, setSelectedView] = useState("coaching");
  const [allSounds, setAllSounds] = useState([]);
  const [recommendedSounds, setRecommendedSounds] = useState([]);
  const [categorizedSounds, setCategorizedSounds] = useState(null);
  const [allArticles, setAllArticles] = useState([]);
  const [recommendedArticles, setRecommendedArticles] = useState([]);
  const [categorizedArticles, setCategorizedArticles] = useState(null);
  const [selectedArticleCategory, setSelectedArticleCategory] = useState(null);
  const [selectedSoundCategory, setSelectedSoundCategory] = useState(null);
  const [isLoadingDone, setIsLoading] = useState(true);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedSound, setSelectedSound] = useState(null);

  const [showLandingPage, setShowLandingPage] = useState(false);

  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    getSounds();
    getArticles();
  }, []);

  const getSounds = async () => {
    const soundsURL = `${SOUNDS_DATA_API}?language=${getLanguage()}`;
    try {
      const response = await fetch(soundsURL);
      const data = await response.json();

      categorizeSounds(data);
    } catch (error) {
      return [];
    }
  };

  const getArticles = async () => {
    const articlesURL = `${ARTICLES_DATA_API}?language=${getLanguage()}`;
    try {
      const response = await fetch(articlesURL);
      const data = await response.json();

      categorizeArticles(data);
    } catch (error) {
      return [];
    }
  };

  function categorizeArticles(articles) {
    const categorized = articles.reduce((acc, article) => {
      const { category, ...rest } = article;
      if (!acc[category]) {
        acc[category] = { category, articles: [] };
      }
      acc[category].articles.push(rest);
      return acc;
    }, {});

    setAllArticles(articles);
    setRecommendedArticles(shuffleArray(articles).slice(0, 3));
    setCategorizedArticles(Object.values(categorized));
    setSelectedArticleCategory(Object.values(categorized)[0]);
  }

  function categorizeSounds(sounds) {
    const categorized = sounds.reduce((acc, article) => {
      const { category, ...rest } = article;
      if (!acc[category]) {
        acc[category] = { category, sounds: [] };
      }
      acc[category].sounds.push(rest);
      return acc;
    }, {});

    setAllSounds(sounds);
    setRecommendedSounds(shuffleArray(sounds).slice(0, 3));
    setCategorizedSounds(Object.values(categorized));
    setSelectedSoundCategory(Object.values(categorized)[0]);
  }

  const mapSoundCategoryToEmoji = (category) => {
    switch (category) {
      case "instruments":
        return "🎻";
      case "nature":
        return "🌿";
      case "animals":
        return "🐦";
      case "mantras":
        return "🧘";
      default:
        return "🧘";
    }
  };

  const mapArticleCategoryToEmoji = (category) => {
    switch (category) {
      case "psychology":
        return "🧠";
      case "home":
        return "🏡";
      case "practice":
        return "🧘";
      case "astrology":
        return "🌌";
      case "woman":
        return "👩";
      case "tarot":
        return "🔮";
      case "energy":
        return "🌿";
      case "rituals":
        return "🕯️";
      case "sound":
        return "🎵";
      case "affirmations":
        return "🧘";
      case "runes":
        return "🪨";
      case "tarot":
        return "🎴";
      default:
        return "🧘";
    }
  };

  var sliderSettings = {
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
        },
      },
    ],
  };

  return (
    <StyledWisdomPage>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={200}
        width={200}
      />
      <PageTitle title={t("wisdom")} text={t("wisdom-text")}></PageTitle>

      {/*<div className="section-select animated fadeIn">
        <div
          className={`section-select-entry ${selectedView === 'coaching' ? 'is-active' : ''}`}
          onClick={() => setSelectedView('coaching')}
        >
          {t('coaching')}
        </div>
        <div
          className={`section-select-entry ${selectedView === 'meditation' ? 'is-active' : ''}`}
          onClick={() => setSelectedView('meditation')}
        >
          {t('meditation')}
        </div>
        <div
                className={`section-select-entry ${selectedView === 'reading' ? 'is-active' : ''}`}
                onClick={() => setSelectedView('reading')}
              >
                {t('reading')}
        </div>
      </div>*/}

      {selectedView === "coaching" && <CoachingSessions></CoachingSessions>}

      {selectedView === "meditation" && (
        <>
          <SectionHeader isLeft={true} title={t(`recommended`)}></SectionHeader>

          <div className="animated fadeIn">
            {recommendedSounds && (
              <StyledSlider {...sliderSettings} hasMaxWidth={true}>
                {recommendedSounds.map((sound, soundIndex) => (
                  <ContentCardBig
                    key={"sound" + soundIndex}
                    title={sound.title}
                    text={sound.shorttext}
                    isLocked={!isPremium}
                    image={`${API_URL}/api/images/watercolor/${encodeURIComponent(
                      sound.topic.toLowerCase().replace(/ /g, "_"),
                    )}.jpg`}
                    onClick={() => {
                      if (!isPremium) {
                        setShowLandingPage(true);
                      } else {
                        setSelectedSound(sound);
                      }
                    }}
                  ></ContentCardBig>
                ))}
              </StyledSlider>
            )}
          </div>

          <SectionHeader isLeft={true} title={t(`discover`)}></SectionHeader>

          <div className="tag-cloud is-left animated fadeIn">
            {categorizedSounds &&
              categorizedSounds.map((category, index) => (
                <div
                  key={category + index}
                  onClick={() =>
                    setSelectedList({
                      title: t(`article-tags.${category.category}`),
                      list: category.sounds,
                      listTitleProp: "title",
                      listTextProp: "shorttext",
                      listImageProp: "topic",
                      generatedImage: true,
                      isLocked: true,
                    })
                  }
                  className={`tag`}
                >
                  <div className="tag-icon">
                    {mapSoundCategoryToEmoji(category.category)}
                  </div>
                  {t(`article-tags.${category.category}`)} (
                  {category.sounds.length})
                </div>
              ))}
          </div>

          {categorizedSounds &&
            categorizedSounds.map((category, index) => (
              <div className="animated fadeIn" key={category.category + index}>
                <SectionHeader
                  isLeft={true}
                  title={t(`article-tags.${category.category}`)}
                  onShowMore={() =>
                    setSelectedList({
                      title: t(`article-tags.${category.category}`),
                      list: category.sounds,
                      listTitleProp: "title",
                      listTextProp: "shorttext",
                      listImageProp: "topic",
                      generatedImage: true,
                      isLocked: true,
                    })
                  }
                  showMoreText={`${t("show-all")} (${category.sounds.length})`}
                ></SectionHeader>

                {category &&
                  category.sounds.slice(0, 3).map((sound, soundIndex) => (
                    <>
                      <ContentCard
                        key={"sound" + soundIndex}
                        title={sound.title}
                        text={sound.shorttext}
                        isLocked={!isPremium}
                        onClick={() => {
                          if (!isPremium) {
                            setShowLandingPage(true);
                          } else {
                            setSelectedSound(sound);
                          }
                        }}
                        image={`${API_URL}/api/images/watercolor/${encodeURIComponent(
                          sound.topic.toLowerCase().replace(/ /g, "_"),
                        )}.jpg`}
                      ></ContentCard>
                    </>
                  ))}
              </div>
            ))}

          {selectedSound && (
            <Modal
              onClose={() => {
                setSelectedSound(null);
              }}
            >
              <StoryPage
                title={selectedSound.title}
                text={selectedSound.content}
                filePath={selectedSound.file_path}
                handleClose={() => {
                  setSelectedSound(null);
                }}
              ></StoryPage>
            </Modal>
          )}
        </>
      )}

      {selectedView === "reading" && (
        <>
          <SectionHeader
            isLeft={true}
            title={t(`wisdom-of-the-day`)}
          ></SectionHeader>

          <div className="animated fadeIn">
            {recommendedArticles && (
              <StyledSlider {...sliderSettings} hasMaxWidth={true}>
                {recommendedArticles.map((article, articleIndex) => (
                  <ContentCardBig
                    key={"article" + articleIndex}
                    title={article.title}
                    text={article.shorttext}
                    isLocked={false}
                    image={`${API_URL}/api/images/watercolor/${encodeURIComponent(
                      article.topic.toLowerCase().replace(/ /g, "_"),
                    )}.jpg`}
                    onClick={() => {
                      setSelectedArticle(article);
                    }}
                  ></ContentCardBig>
                ))}
              </StyledSlider>
            )}
          </div>

          <SectionHeader isLeft={true} title={t(`discover`)}></SectionHeader>

          <div className="tag-cloud is-left animated fadeIn">
            {categorizedArticles &&
              categorizedArticles.map((category, index) => (
                <div
                  key={category + index}
                  onClick={() =>
                    setSelectedList({
                      title: t(`article-tags.${category.category}`),
                      list: category.articles,
                      listTitleProp: "title",
                      listTextProp: "shorttext",
                      listImageProp: "topic",
                      generatedImage: true,
                      isLocked: true,
                      onClick: (index) => {
                        if (isPremium) {
                          setSelectedArticle(category.articles[index]);
                        } else {
                          setShowLandingPage(true);
                        }
                      },
                    })
                  }
                  className={`tag`}
                >
                  <div className="tag-icon">
                    {mapArticleCategoryToEmoji(category.category)}
                  </div>
                  {t(`article-tags.${category.category}`)} (
                  {category.articles.length})
                </div>
              ))}
          </div>

          {categorizedArticles &&
            categorizedArticles.map((category, index) => (
              <div className="animated fadeIn" key={category.category + index}>
                <SectionHeader
                  isLeft={true}
                  title={t(`article-tags.${category.category}`)}
                  onShowMore={() =>
                    setSelectedList({
                      title: t(`article-tags.${category.category}`),
                      list: category.articles,
                      listTitleProp: "title",
                      listTextProp: "shorttext",
                      listImageProp: "topic",
                      generatedImage: true,
                      isLocked: true,
                    })
                  }
                  showMoreText={`${t("show-all")} (${category.articles.length})`}
                ></SectionHeader>

                {category &&
                  category.articles.slice(0, 3).map((article, articleIndex) => (
                    <div key={`article-categorized-articles-${articleIndex}`}>
                      <ContentCard
                        key={"article" + articleIndex}
                        title={article.title}
                        text={article.shorttext}
                        isLocked={!isPremium}
                        onClick={() => {
                          if (!isPremium) {
                            setShowLandingPage(true);
                          } else {
                            setSelectedArticle(article);
                          }
                        }}
                        image={`${API_URL}/api/images/watercolor/${encodeURIComponent(
                          article.topic.toLowerCase().replace(/ /g, "_"),
                        )}.jpg`}
                      ></ContentCard>
                    </div>
                  ))}
              </div>
            ))}

          {selectedArticle && (
            <Modal
              onClose={() => {
                setSelectedArticle(null);
              }}
            >
              <StoryPage
                title={selectedArticle.title}
                text={selectedArticle.content}
                handleClose={() => {
                  setSelectedArticle(null);
                }}
              ></StoryPage>
            </Modal>
          )}
        </>
      )}

      {selectedList && (
        <Modal
          onClose={() => {
            setSelectedList(null);
          }}
        >
          <WisdomListPage
            title={selectedList.title}
            list={selectedList.list}
            listTitleProp={selectedList.listTitleProp}
            listTextProp={selectedList.listTextProp}
            listImageProp={selectedList.listImageProp}
            generatedImage={selectedList.generatedImage}
            isLocked={selectedList.isLocked}
            onClick={(index) => {
              if (!isPremium) {
                setShowLandingPage(true);
              } else {
                if (selectedView === "meditation") {
                  setSelectedSound(selectedList.list[index]);
                } else {
                  setSelectedArticle(selectedList.list[index]);
                }
              }
            }}
          ></WisdomListPage>
        </Modal>
      )}

      {showLandingPage && (
        <Modal
          onClose={() => {
            setShowLandingPage(false);
          }}
        >
          <OnboardingPage
            onClose={() => setShowLandingPage(false)}
          ></OnboardingPage>
        </Modal>
      )}
    </StyledWisdomPage>
  );
}
