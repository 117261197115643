import ContentCard from "components/ContentCard";
import PageTitle from "components/PageTitle";
import { API_URL } from "constants/URLS";
import React from "react";
import styled from "styled-components";

const StyledWisdomListPage = styled.div`
  max-width: 580px;
  margin: 60px 0 20px;
  width: 100%;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }
`;

export default function WisdomListPage({
  title,
  list,
  listTitleProp,
  listTextProp,
  listImageProp,
  generatedImage = true,
  isLocked,
  onClick,
}) {
  return (
    <>
      <StyledWisdomListPage>
        <PageTitle title={title}></PageTitle>

        <div className="animated fadeIn">
          {list.map((item, index) => (
            <ContentCard
              key={"listitem" + index}
              title={item[listTitleProp]}
              text={item[listTextProp]}
              isLocked={isLocked}
              onClick={() => onClick(item)}
              image={
                generatedImage
                  ? `${API_URL}/api/images/watercolor/${encodeURIComponent(
                      item[listImageProp].toLowerCase().replace(/ /g, "_"),
                    )}.jpg`
                  : item[listImageProp]
              }
            ></ContentCard>
          ))}
        </div>
      </StyledWisdomListPage>
    </>
  );
}
