// eslint-disable-file missing semicolon
import { APIContextProvider } from "./context/APIcontext";
import { AlertProvider } from "./context/AlertContext"; // Import useAlert hook
import React, { useContext, useRef, useEffect, useState } from "react";
import { useTranslation, I18nextProvider } from "react-i18next";
import OverviewPage from "./pages/OverviewPage/OverviewPage";
import { StyledApp } from "./StyledApp";
import { StyledElements } from "StyledElements";
import { ShareImageContextProvider } from "context/ShareContext";
import { StyleSheetManager, createGlobalStyle } from "styled-components";
import ReactDOM from "react-dom/client";
import i18next from "i18next";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  :host {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  body {
    font-family: Assistant, sans-serif;
    line-height: 1.4;
    font-size: 16px;
    font-weight: 400;
    color: #111;
    line-height: 1.2;
    min-height: 100vh;
  }

  body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(130deg, #fff, #f8efea);
    z-index: -1;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const StyledComponentsWrapper = ({ children, target }) => {
  return (
    <StyleSheetManager target={target}>
      <GlobalStyle />
      {children}
    </StyleSheetManager>
  );
};

const ShadowRootWrapper = ({ children }) => {
  const hostRef = useRef(null);
  const shadowRootRef = useRef(null);
  const rootRef = useRef(null);
  const isMountedRef = useRef(true);
  const { t } = useTranslation("common");

  // Initialize shadow root and React root
  useEffect(() => {
    if (hostRef.current && !shadowRootRef.current) {
      // Create shadow root
      const shadowRoot = hostRef.current.attachShadow({ mode: "open" });
      shadowRootRef.current = shadowRoot;

      // Add font styles
      const fontStyle = document.createElement("style");
      fontStyle.textContent = `
        @import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;500;600;700&display=swap');
      `;
      shadowRoot.appendChild(fontStyle);

      // Create a container for React content
      const container = document.createElement("div");
      container.style.width = "100%";
      container.style.height = "100%";
      shadowRoot.appendChild(container);

      // Create and store the root
      rootRef.current = ReactDOM.createRoot(container);

      // Initial render
      rootRef.current.render(
        <I18nextProvider i18n={i18next}>
          <StyledComponentsWrapper target={shadowRoot}>
            {children}
          </StyledComponentsWrapper>
        </I18nextProvider>,
      );
    }

    return () => {
      isMountedRef.current = false;
      if (rootRef.current) {
        rootRef.current.unmount();
      }
    };
  }, []); // Empty dependency array for initialization

  // Handle content updates
  useEffect(() => {
    if (isMountedRef.current && rootRef.current && shadowRootRef.current) {
      rootRef.current.render(
        <I18nextProvider i18n={i18next}>
          <StyledComponentsWrapper target={shadowRootRef.current}>
            {children}
          </StyledComponentsWrapper>
        </I18nextProvider>,
      );
    }
  }, [children, t]); // Dependencies for content updates

  return <div ref={hostRef} style={{ display: "contents" }} />;
};

function App({ data }) {
  const { t } = useTranslation("common");

  return (
    <ShadowRootWrapper>
      <StyledApp>
        <AlertProvider>
          <StyledElements>
            {data.module === "landing-page" && (
              <APIContextProvider>
                <></>
              </APIContextProvider>
            )}
            {data.module === "checkout-page" && (
              <APIContextProvider>
                <></>
              </APIContextProvider>
            )}
            {data.module === "onboarding" && <OnboardingPage />}
            {data.module === "app-widget" && (
              <>
                <APIContextProvider t={t}>
                  <ShareImageContextProvider>
                    <OverviewPage startView={data.startView}></OverviewPage>
                  </ShareImageContextProvider>
                </APIContextProvider>
              </>
            )}
          </StyledElements>
        </AlertProvider>
      </StyledApp>
    </ShadowRootWrapper>
  );
}

export default App;
