import styled from "styled-components";

export const StyledProfilePage = styled.div`
  max-width: 580px;
  margin: 0;
  width: 100%;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }

  .user-logged-in {
  }

  .no-account {
    height: 90vh;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .stats-card-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 3%;
    margin: 20px 0;

    @media (min-width: 720px) {
      padding: 0;
      margin: 20px auto;
    }
  }

  .profile-block-wrapper {
    position: relative;
    margin: 0;
    overflow: hidden;
    border: 1px solid whitesmoke;
    border-radius: 0;
    margin: 20px 3% 20px;
    max-width: 580px;
    cursor: pointer;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;

    @media (min-width: 720px) {
      margin: 20px auto 20px;
    }

    .profile-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;

      .profile-block-left {
        font-size: 14px;
        font-weight: 400;
        color: rgba(18, 18, 18, 0.75);
      }

      .profile-block-right {
        font-size: 14px;
        font-weight: 700;
      }
    }

    &:last-child {
    }
  }
`;
