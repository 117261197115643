import ContentCard from "components/ContentCard";
import PageTitle from "components/PageTitle";
import { API_URL, BASE_URL } from "constants/URLS";
import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAPI } from "context/APIcontext";
import Modal from "components/Modal";
import TarotSpread from "./TarotSpread";
import LandingPage from "pages/LandingPage/LandingPage";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";

const StyledTarotListPage = styled.div`
  max-width: 580px;
  margin: 50px 3% 20px;
  width: 100%;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }
`;

export default function TarotListPage({ title, spread, isLocked, tarotCards }) {
  const { t } = useTranslation("common");
  const { isPremium } = useAPI();

  const [selectedSpread, setSelectedSpread] = useState(null);
  const [showLandingPage, setShowLandingPage] = useState(false);

  return (
    <>
      <StyledTarotListPage>
        <PageTitle
          title={t(`spreads.spread_types.${spread.category}`)}
        ></PageTitle>

        <div className="animated fadeIn">
          {spread &&
            spread.spreads.map((spread, articleIndex) => (
              <div key={`content-card-${articleIndex}`}>
                <ContentCard
                  title={t(`spreads.spreads.${spread.id}.title`)}
                  text={t(`spreads.spreads.${spread.id}.description`)}
                  isLocked={!isPremium && !spread.isFree}
                  image={`${BASE_URL}/react/assets/img/spreads/${spread.image}`}
                  isTarotReading
                  onClick={() => {
                    if (isPremium || spread.isFree) {
                      setSelectedSpread(spread);
                    } else {
                      setShowLandingPage(true);
                    }
                  }}
                ></ContentCard>
              </div>
            ))}
        </div>
      </StyledTarotListPage>

      {selectedSpread && (
        <Modal
          onClose={() => {
            setSelectedSpread(null);
          }}
        >
          <TarotSpread
            spread={selectedSpread}
            tarotCards={tarotCards}
          ></TarotSpread>
        </Modal>
      )}

      {showLandingPage && (
        <Modal
          onClose={() => {
            setShowLandingPage(false);
          }}
        >
          <OnboardingPage
            onClose={() => setShowLandingPage(false)}
          ></OnboardingPage>
        </Modal>
      )}
    </>
  );
}
