import PageTitle from "components/PageTitle";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { API_URL, BASE_URL } from "constants/URLS";
import TarotDetailPageForSpread from "./TarotDetailPageForSpread";
import StyledSlider from "components/StyledSlider";
import Modal from "components/Modal";
import { useAPI } from "context/APIcontext";
import * as animationData from "assets/lottie/swipe-left.json";
import Lottie from "react-lottie";
import ShareIcon from "components/SVG/ShareIcon";
import screenshotShare from "helper/screenshotShare";
import { useShareImageContext } from "context/ShareContext";
import PageTitleForSharing from "components/PageTitleForSharing";
import SectionHeader from "components/SectionHeader";
import BlockButton from "components/buttons/BlockButton";
import { useAlert } from "context/AlertContext";
import TarotCardDetail from "./TarotCardDetail";
import BookIcon from "components/SVG/BookIcon";
import PickCardOverlay from "./PickCardOverlay";
import AIReading from "./AIReading";
import StoryPage from "pages/StoryPage/StoryPage";

const StyledTarotSpread = styled.div`
  margin-top: 70px;
  width: 100%;
  min-height: 80vh;

  .section-select .section-select-entry {
    padding: 8px 20px;
  }

  .show-to-share {
    display: none;
  }

  .share-button-custom {
    color: #c19c7b;
    font-family: "Polarity-Regular", serif;
    font-size: 16px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    &.share-button-custom--is-absolute {
      position: absolute;
      top: auto;
      bottom: 15%;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    &.share-button-custom--is-ai-question {
      position: absolute;
      top: auto;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    &:hover {
      color: #000;

      svg {
        fill: #000;
      }
    }

    svg {
      transition: all 0.3s;
      width: 20px;
      height: 20px;
      fill: #c19c7b;
      margin-right: 5px;
    }

    @media (min-width: 720px) {
      &.share-button-custom--is-absolute {
        top: auto;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, 0%);
      }

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  .tarot-readin-info {
    padding-bottom: 100px;

    .info-container {
      background-color: #fff;
      margin: 0 3% 20px;
      border-radius: 10px;
      padding: 20px;
      border: 1px solid #ddd;
    }

    .card-container {
      position: relative;
      width: 100%;
      min-height: 300px;
      margin-bottom: 60px;
      background-color: whitesmoke;
      border-radius: 10px;
      margin: 0;
      box-sizing: border-box;

      @media screen and (min-width: 768px) {
      }

      .flip-card {
        position: absolute;
        background-color: transparent;
        width: 50px;
        perspective: 1000px;
        transition: all 1s ease-in-out;
        transform: translate(-50%, 0);

        .flip-card-front {
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          overflow: hidden;
        }

        .card-number {
          font-family: "Polarity-Regular", serif;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 23px;
          color: #111;
          font-weight: 700;
        }
      }
    }

    textarea {
      border: 1px solid #ddd;
      border-radius: 10px;
      font-size: 14px;
      padding: 10px;
      height: 100px;
      line-height: 1.2;
      background-color: #fff;
      margin: 0 3% 20px;
      width: 94%;

      &:read-only {
        color: #ddd;
        background-color: whitesmoke;
      }

      &::placeholder {
        color: #ddd;
      }

      @media only screen and (max-width: 375px) {
        font-size: 14px;
      }
    }

    .settings-container {
      margin: 30px 3% 20px;
    }

    .section-header.is-left {
      margin-top: 0;
    }
  }

  .original-view {
    width: 100%;
    min-height: 500px;

    .lection-of-card-title {
      font-family: "Polarity-Regular", serif;
      line-height: 1.4 !important;
      font-size: 20px;
      font-weight: 800;
      border-top: 1px solid #eee;
      padding-top: 20px;
      margin-top: 20px;
      text-align: center;
      opacity: 0;
      transition: all 1s ease-in-out;
      min-height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &.revealed {
        opacity: 1;
      }
    }

    .lection-of-card-lesson {
      margin-top: 20px;
      font-size: 16px;
      text-align: center;
      opacity: 0;
      transition: all 1s ease-in-out;

      &.revealed {
        opacity: 1;
      }
    }

    .lection-of-card-text {
      font-family: "Polarity-Regular", serif;
      line-height: 1.4 !important;
      font-size: 20px;
      font-weight: 700 !important;
      color: #111;
      text-align: center;
      margin: 10px auto 20px;
      opacity: 0;
      transition: all 1s ease-in-out;
      max-width: 420px;

      &.revealed {
        opacity: 1;
      }
    }

    .lection-of-card-keywords {
      margin-top: 20px;
      font-size: 13px;
      text-align: center;
      opacity: 0;
      transition: all 1s ease-in-out;

      &.revealed {
        opacity: 1;
      }
    }

    .card-container {
      position: relative;
      width: 100%;
      min-height: 500px;
      //left: -9%;

      @media screen and (min-width: 768px) {
        //left: -8%;
      }
    }

    .flip-card {
      position: absolute;
      background-color: transparent;
      width: 18vw;
      height: 31vw;
      perspective: 1000px;
      transition: all 1s ease-in-out;
      max-width: 100px;
      max-height: 173px;
      transform: translate(-50%, 0);

      &.is-current {
        opacity: 1;
      }

      &.is-past {
        opacity: 1;
      }

      &.reversed .flip-card-back img {
        transform: rotateX(180deg);
      }
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    .flip-card.revealed .flip-card-inner {
      transform: rotateY(180deg);
    }

    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      overflow: hidden;
    }

    .flip-card-front {
      background-color: #bbb;
      color: black;
    }

    /* Style the back side */
    .flip-card-back {
      color: white;
      transform: rotateY(180deg);
      transition: all 1s ease-in-out;
    }

    .task {
      .task-image {
        background-size: 50% 90%;
        background-repeat: no-repeat;

        &:after {
          display: none;
        }
      }
    }

    .card-number {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }

    .selected-card-title {
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      font-size: 10px;
      margin: 0;
      padding: 0;
      left: 0;
      width: 100%;
      line-height: 1.2;
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: none;

      small {
        font-size: 8px;
      }
    }
  }

  .list-view {
    padding-bottom: 50px;

    .page-title-big {
      font-family: "Polarity-Regular", serif;
      //font-family: "breathing", sans-serif;
      padding: 0 3% 10px;
      line-height: 1 !important;
      font-size: 16px;
      font-weight: 700 !important;
      color: #111;
      text-align: center;
      position: relative;
      margin: 0 30px 0;
    }

    .page-title-very-big {
      font-family: "Polarity-Regular", serif;
      //font-family: "breathing", sans-serif;
      padding: 0 3% 0;
      line-height: 1 !important;
      font-size: 20px;
      font-weight: 700 !important;
      color: #111;
      text-align: center;
      position: relative;
      margin: 0 20px 0;
    }

    .page-title-small {
      font-size: 14px;
      font-weight: 400;
      font-family: Assistant, sans-serif;
      line-height: 1.4;
      color: #111;
      min-height: 23px;
      text-align: center;
      max-width: 300px;
      margin: 0 auto 20px;
    }

    .flip-card {
      margin: 20px auto 0;
    }

    .page-title-small {
      margin-top: 10px;
    }

    .slick-dots {
      position: absolute;
      bottom: -25px;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;

      li {
        position: relative;
        display: inline-block;

        margin: 0 10px;
        padding: 0;
        cursor: pointer;

        &.slick-active {
          button {
            background-color: #c19c7b;
          }
        }
      }

      button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 10px;
        height: 10px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        background: #fff;
        border-radius: 50%;
        box-shadow: #cccccc 0 0 16px;
      }
    }

    .card-title {
      font-weight: 800;
      text-align: center;
      opacity: 0;
      transition: all 1s ease-in-out;
      color: #c19c7b;
      font-family: "breathing", sans-serif;
      font-size: 22px;
      line-height: 1.2;
      position: relative;
      top: 10px;
      left: 4px;
      margin: 20px 0;
      display: block;

      &.revealed {
        opacity: 1;
      }
    }

    .lection-of-card-lesson {
      line-height: 1.4;
      font-size: 18px;
      font-weight: 700;
      padding: 0;
      margin: 0;
      text-align: center;
      font-family: "Polarity-Regular", serif;
      margin: 0 10% 6px;
      display: block;
      opacity: 0;
      transition: all 1s ease-in-out;

      display: none;

      &.revealed {
        opacity: 1;
      }
    }

    .lection-of-card-text {
      line-height: 1.4;
      font-size: 18px;
      font-weight: 700;
      padding: 0;
      margin: 0;
      text-align: center;
      font-family: "Polarity-Regular", serif;
      margin: 0 10% 6px;
      display: block;
      opacity: 0;
      transition: all 1s ease-in-out;

      &.revealed {
        opacity: 1;
      }
    }

    .lection-of-card-keywords {
      margin-top: 20px;
      font-size: 13px;
      text-align: center;
      opacity: 0;
      transition: all 1s ease-in-out;

      &.revealed {
        opacity: 1;
      }
    }

    .card-container {
      position: relative;
      width: 100%;
      height: 500px;
    }

    .flip-card {
      position: relative;
      background-color: transparent;
      width: 250px;
      height: 432px;
      perspective: 1000px;
      margin-top: 20px;

      &.reversed .flip-card-back {
        transform: rotateX(180deg);
      }
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    .flip-card.revealed .flip-card-inner {
      transform: rotateY(180deg);
    }

    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      overflow: hidden;
    }

    .flip-card-front {
      background-color: #bbb;
      color: black;
    }

    /* Style the back side */
    .flip-card-back {
      color: white;
      transform: rotateY(180deg);
      transition: all 1s ease-in-out;
    }

    .task {
      .task-image {
        background-size: 50% 90%;
        background-repeat: no-repeat;

        &:after {
          display: none;
        }
      }
    }
  }

  .tarot-hint {
    position: fixed;
    top: 16px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    border-radius: 10px;
    left: 0;
  }
`;

export default function TarotSpread({
  spread,
  tarotCards,
  startView = "info",
  onCardReveal,
}) {
  const { t } = useTranslation("common");

  const { updateTarotSpread, triggerUpdate } = useAPI();

  const { showAlert } = useAlert();

  const { openModal } = useShareImageContext();

  const [selectedView, setSelectedView] = useState(startView);
  const [currentSpread, setCurrentSpread] = useState(spread);
  const [selectedCard, setSelectedCard] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [showPageTitleForSharing, setShowPageTitleForSharing] = useState(false);
  const [isReversedCards, setIsReversedCards] = useState(true);
  const [isPickCards, setIsPickCards] = useState(false);
  const [userQuestion, setUserQuestion] = useState("");
  const [userQuestionAnswered, setUserQuestionAnswered] = useState(false);

  const [manualPickCardIndex, setManualPickCardIndex] = useState(null);

  const [readingIsDone, setReadingIsDone] = useState(false);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [infoOpened, setInfoOpened] = useState(false);
  const [questionOpened, setQuestionOpened] = useState(false);
  const [aiReading, setAIReading] = useState(false);

  useEffect(() => {
    prepareSpread(spread);

    //document.title = `${t('spreads.spreads.' + spread.id + '.title')}`;
  }, []);

  function prepareSpread(spread) {
    const shuffledTarotCards = shuffle([...tarotCards]);

    // Assign cards to spread positions without repetition
    spread.cards.forEach((element, index) => {
      if (!element.isRevealed) {
        element.isRevealed = false;

        // Remove the assigned card from the shuffledTarotCards array
        shuffledTarotCards.splice(index, 1); // Remove the card at the current index
      }
    });

    if (spread.nextCardToReveal === undefined) {
      spread.nextCardToReveal = 0;
    }

    if (spread.nextCardToReveal + 1 >= spread.cards.length) {
      setReadingIsDone(true);
    }

    if (spread.userQuestion !== undefined) {
      setUserQuestion(spread.userQuestion);
      setUserQuestionAnswered(true);
    }

    setCurrentSpread({ ...spread });
  }

  function resetSpread(reversedAllowed) {
    const shuffledTarotCards = shuffle([...tarotCards]);

    // Assign cards to spread positions without repetition
    spread.cards.forEach((element, index) => {
      element.isRevealed = false;
      element.reversed = reversedAllowed ? Math.random() >= 0.5 : false;
      element.selectedCard = shuffledTarotCards[index];
    });

    spread.nextCardToReveal = 0;

    setCurrentSpread({ ...spread });

    setReadingIsDone(false);

    setTimeout(() => {
      updateTarotSpread({
        ...currentSpread,
      });
    }, 500);
  }

  function handleChangeValue(reversedAllowed = false) {
    if (currentSpread.nextCardToReveal > 0) {
      showAlert(
        t("spread.reset-overlay-headline"),
        t("spread.reset-overlay-text"),
        () => {
          resetSpread(reversedAllowed);
        },
        () => {},
      );
    } else {
      resetSpread(reversedAllowed);
    }
  }

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function pickCard(clickedIndex) {
    const cardToUpdate = currentSpread.cards[clickedIndex];

    if (cardToUpdate.isRevealed) {
      setSelectedCard(cardToUpdate.selectedCard);
      setClickedIndex(clickedIndex);
    } else {
      if (isPickCards) {
        setManualPickCardIndex(clickedIndex);
      } else {
        revealCard(clickedIndex);
      }
    }
  }

  function handleManualCardPick(card, index) {
    const cardToUpdate = currentSpread.cards[index];
    cardToUpdate.selectedCard = card;

    setTimeout(() => {
      cardToUpdate.isRevealed = true;
    }, 500);

    if (isReversedCards) {
      cardToUpdate.reversed = Math.random() >= 0.5;
      if (cardToUpdate.reversed) {
        cardToUpdate.reversedClass = true;
      } else {
        cardToUpdate.reversedClass = false;
      }
    } else {
      cardToUpdate.reversed = false;
      cardToUpdate.reversedClass = false;
    }

    onCardReveal();

    if (currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length) {
      setReadingIsDone(true);
    }

    setCurrentSpread({
      ...currentSpread,
      cards: currentSpread.cards,
      nextCardToReveal: currentSpread.nextCardToReveal + 1,
      isDone: currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length,
      readingDone:
        currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length,
    });

    setTimeout(() => {
      updateTarotSpread({
        ...currentSpread,
        cards: currentSpread.cards,
        nextCardToReveal: currentSpread.nextCardToReveal + 1,
        isDone:
          currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length,
        readingDone:
          currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length,
      });
    }, 500);

    setManualPickCardIndex(null);
  }

  function handleAIRreading(data) {
    setAIReading(data);
    setQuestionOpened(false);

    setTimeout(() => {
      updateTarotSpread({
        ...currentSpread,
        userQuestion: userQuestion,
        answer: data,
      });
    }, 500);
  }

  function revealCard(clickedIndex) {
    if (clickedIndex === currentSpread.nextCardToReveal) {
      // Filter out cards that have already been used in the spread
      const availableCards = tarotCards.filter(
        (card) =>
          !currentSpread.cards.some(
            (spreadCard) => spreadCard.selectedCard === card,
          ),
      );

      // Randomly select a card from the available cards
      const randomIndex = Math.floor(Math.random() * availableCards.length);
      const selectedCard = availableCards[randomIndex];

      // Determine if the card should be reversed
      const isReversed = isReversedCards ? Math.random() >= 0.5 : false;

      const updatedCards = currentSpread.cards.map((card, index) => {
        if (index === clickedIndex) {
          const updatedCard = {
            ...card,
            isRevealed: true,
            selectedCard: selectedCard,
            reversed: isReversed,
            reversedClass: isReversed ? true : false,
          };
          return updatedCard;
        }
        return card;
      });

      // Check if the reading is done
      const nextCardToReveal = currentSpread.nextCardToReveal + 1;
      const isReadingDone = nextCardToReveal >= currentSpread.cards.length;

      if (currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length) {
        setReadingIsDone(true);
      }

      setCurrentSpread({
        ...currentSpread,
        cards: updatedCards,
        nextCardToReveal: nextCardToReveal,
        isDone: isReadingDone,
        readingDone: isReadingDone,
      });

      setTimeout(() => {
        updateTarotSpread({
          ...currentSpread,
          cards: updatedCards,
          nextCardToReveal: nextCardToReveal,
          isDone: isReadingDone,
          readingDone: isReadingDone,
        });

        triggerUpdate();
      }, 500);
    }
  }

  const takeAndShareScreenshot = async () => {
    setShowPageTitleForSharing(true);
    setTimeout(() => {
      takeScreenShot();
    }, 0);
  };

  const takeScreenShot = async () => {
    const imageData = await screenshotShare();
    openModal(imageData);
    setShowPageTitleForSharing(false);
  };

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });

  var settings = {
    dots: true,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    //variableWidth: true,
    swipeToSlide: true,
    spacing: 0,
  };

  return (
    <StyledTarotSpread>
      {!showPageTitleForSharing && (
        <div className="section-select animated fadeIn hide-to-share">
          <div
            className={`section-select-entry ${selectedView === "info" ? "is-active" : ""}`}
            onClick={() => setSelectedView("info")}
          >
            {t("spreads.view-information")}
          </div>
          <div
            className={`section-select-entry ${selectedView === "original" ? "is-active" : ""}`}
            onClick={() => setSelectedView("original")}
          >
            {t("spreads.view-original")}
          </div>
          <div
            className={`section-select-entry ${selectedView === "list" ? "is-active" : ""}`}
            onClick={() => setSelectedView("list")}
          >
            {t("spreads.view-list")}
          </div>
          {/*<div
          className={`section-select-entry ${selectedView === 'questions' ? 'is-active' : ''}`}
          onClick={() => setSelectedView('questions')}
          >
            {t('spreads.questions')}
          </div>*/}
        </div>
      )}

      {selectedView != "info" && (
        <div className="tarot-hint hide-to-share animation animated fadeIn">
          {t("spreads.spread-card-hint")}
        </div>
      )}

      {selectedView === "info" && (
        <>
          <div className="tarot-readin-info animation animated fadeIn">
            <PageTitle
              title={t("spreads.spreads." + spread.id + ".title")}
              text={t("spreads.spreads." + spread.id + ".description")}
            ></PageTitle>

            <SectionHeader
              title={t("spread.spread-general-headline")}
              noSpacing
              isLeft
            />

            <div className="info-container">
              <div className="card-container">
                {currentSpread.cards.map((card, i) => (
                  <div
                    key={`info-card-${i}`}
                    className={`flip-card animation animated fadeIn`}
                    style={{
                      marginTop: "-50px",
                      left: card.position.x,
                      top: card.position.y,
                      transform: card.position.rotate || "",
                    }}
                  >
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img
                          src={`${API_URL}/api/images/tarotcards/back.jpg`}
                        />
                        <div className="card-number">{i + 1}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {currentSpread.cards.map((card, cardIndex) => (
                <div key={`card-text-${cardIndex}`}>
                  <SectionHeader
                    title={`${cardIndex + 1} ${t(`spreads.card`)}: ${t(
                      `spreads.spreads.${spread.id}.cards.${cardIndex}.name`,
                    )}`}
                    text={t(
                      `spreads.spreads.${spread.id}.cards.${cardIndex}.description`,
                    )}
                    noSpacing
                    isSmall
                  />
                </div>
              ))}
            </div>

            <SectionHeader title={t("settings")} noSpacing isLeft />

            <div className="settings-container">
              <div className="settings">
                <div className="settings-row">
                  <label>{t("spreads.reversed-cards")}</label>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isReversedCards}
                      onChange={() => {
                        setIsReversedCards(!isReversedCards);
                        handleChangeValue(!isReversedCards);
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="settings-row">
                  <label>{t("spreads.pick-cards")}</label>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isPickCards}
                      onChange={() => {
                        setIsPickCards(!isPickCards);
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            {/*
            <SectionHeader
              title={t('spread.your-question-headline')}
              text={t('spread.your-question-text')}
              noSpacing
            />

            
            <textarea
              placeholder={t('spread.your-question-placeholder')}
              value={userQuestion}
              onChange={(e) => {
                setUserQuestion(e.target.value);
              }}
              readOnly={userQuestionAnswered}
            ></textarea> 
            */}
          </div>
          <BlockButton
            isBottom
            text={t("start-tarot-reading")}
            onClick={() => {
              setSelectedView("original");
            }}
          ></BlockButton>
        </>
      )}

      {selectedView === "original" && (
        <>
          {showPageTitleForSharing && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: 0,
                width: "100%",
              }}
            >
              {/*<PageTitleForSharing
                title={t('spreads.sharing-headline')}
                text={`${t('spreads.spreads.' + spread.id + '.title')}: ${t(
                  'spreads.spreads.' + spread.id + '.description',
                )}`}
                animated={false}
                ></PageTitleForSharing>*/}
            </div>
          )}
          <div className="original-view">
            <div className="card-container ">
              {currentSpread.cards.map((card, i) => (
                <div
                  className={`flip-card animation animated fadeIn ${
                    card.isRevealed ? "revealed" : ""
                  } ${i === currentSpread.nextCardToReveal ? "is-current" : ""} ${
                    i < currentSpread.nextCardToReveal ? "is-past" : ""
                  } ${card.reversedClass ? "reversed" : ""}`}
                  style={{
                    left: card.position.x,
                    top: card.position.y,
                    transform: card.position.rotate || "",
                  }}
                  onClick={() => pickCard(i)}
                  key={i}
                >
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img src={`${API_URL}/api/images/tarotcards/back.jpg`} />
                      <div className="card-number">{i + 1}</div>
                    </div>
                    <div className="flip-card-back">
                      {card.selectedCard && (
                        <img
                          src={`${API_URL}/api/images/tarotcards/${card.selectedCard.image}.jpg`}
                          alt={card.selectedCard.title}
                        />
                      )}
                      <div className="selected-card-title">
                        {card.selectedCard && (
                          <>
                            {`${card.selectedCard.title}`}
                            {card.reversed && (
                              <>
                                <br />
                                <small>{t("spreads.reversed")}</small>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {readingIsDone && (
            <>
              <BlockButton
                isBottomSecond
                isOutlined
                noBorder
                className="animation animated fadeIn hide-to-share"
                text={t("share-this-spread")}
                icon={<ShareIcon />}
                onClick={() => {
                  takeAndShareScreenshot();
                }}
              ></BlockButton>

              <BlockButton
                isBottom
                className="animation animated fadeIn hide-to-share"
                text={t("analyse")}
                icon={<BookIcon />}
                onClick={() => {
                  setQuestionOpened(true);
                }}
              ></BlockButton>
            </>
          )}

          {showPageTitleForSharing && (
            <div
              className="screenshot-watermark"
              style={{ visibility: "visible" }}
            >
              <img
                className="screenshot-watermark-logo"
                src={`${BASE_URL}/react/assets/img/tiny-affirmation-logo-share.png`}
              ></img>
              <div className="screenshot-watermark-text">
                <h4>{t("screenshot-watermark-headline")}</h4>
                <span>{t("screenshot-watermark-text")}</span>
              </div>
            </div>
          )}
        </>
      )}

      {selectedView === "list" && (
        <div
          className="list-view animation animated fadeIn"
          style={{ marginTop: showPageTitleForSharing ? "" : "" }}
        >
          {/*showPageTitleForSharing && (
            <div style={{ position: 'absolute', top: '10px', left: 0, width: '100%' }}>
              <PageTitleForSharing
                title={t('spreads.sharing-headline')}
                text={`${t('spreads.spreads.' + spread.id + '.title')}: ${t(
                  'spreads.spreads.' + spread.id + '.description',
                )}`}
                animated={false}
              ></PageTitleForSharing>
            </div>
                )*/}

          <StyledSlider {...settings}>
            {currentSpread.cards.map((card, cardIndex) => (
              <div className="page-title" key={`listview-${cardIndex}`}>
                <div className="page-title-very-big">
                  {`${t(`spreads.spreads.${spread.id}.cards.${cardIndex}.name`)}`}
                  :
                </div>

                <div className="page-title-small">
                  {t(
                    `spreads.spreads.${spread.id}.cards.${cardIndex}.description`,
                  )}
                </div>

                <div
                  className={`flip-card ${card.isRevealed ? "revealed" : ""} ${
                    card.reversed ? "reversed" : ""
                  }`}
                  onClick={() => pickCard(cardIndex)}
                >
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        src={`${BASE_URL}/api/images/tarotcards/back.jpg`}
                        alt={`Card ${cardIndex}`}
                      />
                    </div>
                    <div className="flip-card-back">
                      {card.selectedCard && (
                        <img
                          src={`${BASE_URL}/api/images/tarotcards/${card.selectedCard.image}.jpg`}
                          alt={`Card ${cardIndex}`}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {card.selectedCard && (
                  <>
                    <div
                      className={`card-title ${card.isRevealed ? "revealed" : ""}`}
                    >
                      {card.reversed
                        ? card.selectedCard.titleReversed
                        : card.selectedCard.title}
                    </div>

                    <div
                      className={`lection-of-card-lesson ${card.isRevealed ? "revealed" : ""}`}
                    >
                      {t("spreads.lesson-of-this-card-headline")}
                    </div>

                    <div
                      className={`lection-of-card-text ${card.isRevealed ? "revealed" : ""}`}
                    >
                      {!card.reversed
                        ? card.selectedCard.lesson
                        : card.selectedCard.lessonReversed}
                    </div>

                    {card.isRevealed && (
                      <div
                        className="share-button-custom hide-to-share"
                        onClick={() => {
                          takeAndShareScreenshot();
                        }}
                      >
                        <ShareIcon />
                        {t("share-this-card")}
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </StyledSlider>

          {showPageTitleForSharing && (
            <div
              className="screenshot-watermark"
              style={{ visibility: "visible" }}
            >
              <img
                className="screenshot-watermark-logo"
                src={`${BASE_URL}/react/assets/img/tiny-affirmation-logo-share.png`}
              ></img>
              <div className="screenshot-watermark-text">
                <h4>{t("screenshot-watermark-headline")}</h4>
                <span>{t("screenshot-watermark-text")}</span>
              </div>
            </div>
          )}

          {currentSpread.cards.length &&
            currentSpread.cards[0].isRevealed > 1 && (
              <div
                className="story-swipe-animation hide-to-share"
                style={{ opacity: ".5" }}
              >
                <Lottie
                  options={defaultOptions}
                  height={200}
                  width={200}
                  isStopped={defaultOptions.isStopped}
                  isPaused={defaultOptions.isPaused}
                />
              </div>
            )}
        </div>
      )}

      {manualPickCardIndex != null && (
        <Modal
          onClose={() => {
            setManualPickCardIndex(null);
          }}
        >
          <PickCardOverlay
            index={manualPickCardIndex}
            tarotCards={tarotCards}
            currentSpread={spread}
            onCardPicked={(card, index) => {
              handleManualCardPick(card, index);
            }}
          ></PickCardOverlay>
        </Modal>
      )}

      {aiReading && (
        <Modal
          onClose={() => {
            setAIReading(null);
          }}
        >
          <StoryPage
            title={aiReading.title}
            text={aiReading.text}
            handleClose={() => {
              setAIReading(null);
            }}
          ></StoryPage>
        </Modal>
      )}

      {questionOpened && (
        <Modal
          onClose={() => {
            setQuestionOpened(false);
          }}
        >
          <AIReading
            userQuestion={userQuestion}
            currentSpread={currentSpread}
            userQuestionAnswered={userQuestionAnswered}
            updateUserQuestion={(question) => {
              setUserQuestion(question);
            }}
            onAIReading={(reading) => {
              handleAIRreading(reading);
            }}
          ></AIReading>
        </Modal>
      )}

      {selectedCard && (
        <Modal
          onClose={() => {
            setSelectedCard(null);
          }}
        >
          <TarotDetailPageForSpread
            headline={
              currentSpread.cards[clickedIndex].reversed
                ? selectedCard.titleReversed
                : selectedCard.title
            }
            selectedCard={selectedCard}
            reversed={currentSpread.cards[clickedIndex].reversed}
            position={clickedIndex + 1}
            spread={currentSpread}
            spreadTitle={currentSpread.title}
            spreadQuestion={currentSpread.question}
            spreadTopic={currentSpread.cards[clickedIndex].name}
            spreadTopicQuestion={t(
              `spreads.spreads.${spread.id}.cards.${clickedIndex}.description`,
              { lng: "en" },
            )}
            handleClose={() => setSelectedCard(null)}
          ></TarotDetailPageForSpread>
        </Modal>
      )}
    </StyledTarotSpread>
  );
}
