import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAPI } from "context/APIcontext";
import PageTitle from "components/PageTitle";
import TagCloud from "components/TagCloud";
import {
  API_URL,
  ARTICLES_DATA_API,
  BASE_URL,
  TAROT_CARD_API,
} from "constants/URLS";
import Modal from "components/Modal";
import TarotSpread from "./TarotSpread";
import { getLanguage } from "helper/getLanguage";
import LockClosedIcon from "components/SVG/LockClosedIcon";
import SectionHeader from "components/SectionHeader";
import LandingPage from "pages/LandingPage/LandingPage";
import TarotDetailPage from "./TarotDetailPage";
import StoryPage from "pages/StoryPage/StoryPage";
import ContentCard from "components/ContentCard";
import TarotListPage from "./TarotListPage";
import StyledSlider from "components/StyledSlider";
import ContentCardBig from "components/ContentCardBig";
import { getRandomElements, shuffleArray } from "helper/shuffleArray";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";
import LearnTarotPage from "./LearnTarotPage";
import TodaysCard from "./components/todaysCard";
import Lottie from "react-lottie";
import * as animationData from "assets/lottie/animation_onboarding_premium.json";

const StyledTarotPage = styled.div`
  max-width: 580px;
  margin: 0;
  width: 100%;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }
`;

export default function TarotPage() {
  const { t } = useTranslation("common");
  const { getTarotSpreads, isPremium } = useAPI();

  const [showLandingPage, setShowLandingPage] = useState(false);
  const [tarotCards, setTarotCards] = useState(null);
  const [spreadsByCategory, setSpreadsByCategory] = useState(null);
  const [selectedSpreadCategory, setSelectedSpreadCategory] = useState(null);
  const [selectedSpread, setSelectedSpread] = useState(null);
  const [selectedView, setSelectedView] = useState("spreads");
  const [allArticles, setAllArticles] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedTarotCard, setSelectedTarotCard] = useState(null);
  const [isReversed, setIsReversed] = useState(false);
  const [recommendedSpreads, setRecommendedSpreads] = useState(null);

  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    getTodaysSpreads();
    getTarotCards();
    getArticles();
  }, []);

  const getArticles = async () => {
    const articlesURL = `${ARTICLES_DATA_API}?language=${getLanguage()}`;
    try {
      const response = await fetch(articlesURL);
      const data = await response.json();
      const articles = data.filter((article) => article.category === "tarot");
      setAllArticles(articles);
    } catch (error) {
      return [];
    }
  };

  const getTarotCards = async () => {
    const tarotCardsURL = `${TAROT_CARD_API}_${getLanguage()}.json`;
    try {
      const response = await fetch(tarotCardsURL);
      const data = await response.json();
      setTarotCards(data);
    } catch (error) {
      console.error("Failed to load affirmations", error);
      return [];
    }
  };

  const getTodaysSpreads = async () => {
    const spreadsTmp = await getTarotSpreads();
    const spreadsByCategory = sortSpreadsByCategory(spreadsTmp);

    const freeCards = spreadsTmp.filter((item) => item.isFree);
    const paidCards = spreadsTmp.filter((item) => !item.isFree);

    setRecommendedSpreads([...freeCards]);

    setSpreadsByCategory(spreadsByCategory);
    setSelectedSpreadCategory(spreadsByCategory[0]);
  };

  function sortSpreadsByCategory(spreads) {
    const groupedByCategory = spreads.reduce((acc, spread) => {
      // If the type doesn't exist in the accumulator, initialize it
      if (!acc[spread.type]) {
        acc[spread.type] = [];
      }
      acc[spread.type].push(spread);
      return acc;
    }, {});

    // Transform the grouped object into the desired array structure
    return Object.entries(groupedByCategory).map(([category, spreads]) => ({
      category,
      spreads,
    }));
  }

  function getTagIcon(category) {
    switch (category) {
      case "daily":
        return "🗓️";
      case "love":
        return "❤️";
      case "general":
        return "💡";
      case "career":
        return "💼";
      case "money":
        return "💰";
      case "health":
        return "🏥";
      case "family":
        return "👨‍👩‍👧‍👦";
      case "friends":
        return "👯";
      case "spiritual":
        return "🕉️";
      case "lenormand":
        return "📚";
      case "moon":
        return "🌗";
      case "astro":
        return "♈";
      default:
        return "🔮";
    }
  }

  var sliderSettings = {
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
        },
      },
    ],
  };

  return (
    <StyledTarotPage>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={200}
        width={200}
      />
      <PageTitle
        title={t("tarot-detail.tarot-reading-headline")}
        text={t("tarot-detail.daily-tarot-reading-text")}
      ></PageTitle>

      <div className="section-select animated fadeIn">
        {/*<div
          className={`section-select-entry ${selectedView === 'daily' ? 'is-active' : ''}`}
          onClick={() => setSelectedView('daily')}
        >
          {t('tarot-detail.daily')}
        </div>*/}
        <div
          className={`section-select-entry ${selectedView === "spreads" ? "is-active" : ""}`}
          onClick={() => setSelectedView("spreads")}
        >
          {t("tarot-detail.readings")}
        </div>

        <div
          className={`section-select-entry ${selectedView === "cards" ? "is-active" : ""}`}
          onClick={() => setSelectedView("cards")}
        >
          {t("tarot-detail.cards")}
        </div>
        {/*<div
            className={`section-select-entry ${selectedView === 'learn' ? 'is-active' : ''}`}
            onClick={() => setSelectedView('learn')}
          >
            {t('tarot-detail.learn')}
          </div>*/}
      </div>

      {selectedView === "daily" && (
        <>
          {spreadsByCategory && tarotCards && (
            <TodaysCard
              tarotCards={tarotCards}
              onCardReveal={() => {
                getTodaysSpreads();
              }}
            ></TodaysCard>
          )}
        </>
      )}

      {selectedView === "spreads" && (
        <>
          {/*<div className="animated fadeIn">
            {recommendedSpreads && (
              <StyledSlider {...sliderSettings} hasMaxWidth={true}>
                {recommendedSpreads.map((spread, index) => (
                  <ContentCardBig
                    key={`recommended-content-card-${index}`}
                    title={t(`spreads.spreads.${spread.id}.title`)}
                    text={t(`spreads.spreads.${spread.id}.description`)}
                    isLocked={!isPremium && !spread.isFree}
                    image={`${BASE_URL}/react/assets/img/spreads/${spread.image}`}
                    isTarotSpread={true}
                    onClick={() => {
                      if (!spread.isFree && !isPremium) {
                        setShowLandingPage(true);
                      } else {
                        setSelectedSpread(spread);
                      }
                    }}
                  ></ContentCardBig>
                ))}
              </StyledSlider>
            )}
                  </div>*/}

          {spreadsByCategory && tarotCards && (
            <TodaysCard
              tarotCards={tarotCards}
              onCardReveal={() => {
                getTodaysSpreads();
              }}
            ></TodaysCard>
          )}

          <SectionHeader isLeft={true} title={t(`discover`)}></SectionHeader>

          <div className="tag-cloud animation animated fadeIn">
            {spreadsByCategory &&
              spreadsByCategory.map((category, index) => (
                <div
                  key={`tag-${category.category}${index}`}
                  onClick={() =>
                    setSelectedList({
                      title: t(`spreads.spread_types.${category.category}`),
                      spread: category,
                      tarotCards: tarotCards,
                    })
                  }
                  className={`tag`}
                >
                  <div className="tag-icon">
                    {getTagIcon(category.category)}
                  </div>
                  {t(`spreads.spread_types.${category.category}`)}
                </div>
              ))}
          </div>

          {spreadsByCategory &&
            spreadsByCategory.map((category, index) => (
              <div
                className="animation animated fadeIn"
                key={`section-${category.category}${index}`}
              >
                <SectionHeader
                  isLeft={true}
                  title={t(`spreads.spread_types.${category.category}`)}
                  onShowMore={() =>
                    setSelectedList({
                      title: t(`spreads.spread_types.${category.category}`),
                      spread: category,
                      tarotCards: tarotCards,
                    })
                  }
                  showMoreText={`${t("show-all")} (${category.spreads.length})`}
                ></SectionHeader>

                {category &&
                  category.spreads.slice(0, 3).map((spread, articleIndex) => (
                    <div
                      key={`content-card-${category.category}${articleIndex}`}
                    >
                      <ContentCard
                        title={t(`spreads.spreads.${spread.id}.title`)}
                        text={t(`spreads.spreads.${spread.id}.description`)}
                        isLocked={!isPremium && !spread.isFree}
                        image={`${BASE_URL}/react/assets/img/spreads/${spread.image}`}
                        isTarotReading={true}
                        onClick={() => {
                          if (!spread.isFree && !isPremium) {
                            setShowLandingPage(true);
                          } else {
                            setSelectedSpread(spread);
                          }
                        }}
                      ></ContentCard>
                    </div>
                  ))}
              </div>
            ))}
        </>
      )}

      {selectedView === "learn" && (
        <>
          <LearnTarotPage tarotCards={tarotCards} />
        </>
      )}

      {selectedView === "cards" && (
        <>
          <SectionHeader
            title={t("cards-tarot-headline")}
            text={t("cards-tarot-text")}
          ></SectionHeader>

          <div className="tag-cloud animation animated fadeIn">
            <div
              onClick={() => setIsReversed(false)}
              className={`tag ${!isReversed ? "is-active" : ""}`}
            >
              {t(`spreads.normal`)}
            </div>
            <div
              onClick={() => setIsReversed(true)}
              className={`tag ${isReversed ? "is-active" : ""}`}
            >
              {t(`spreads.reversed`)}
            </div>
          </div>

          <div className="animation animated fadeIn">
            {tarotCards.map((card, index) => (
              <ContentCard
                key={"card" + index}
                title={isReversed ? card.titleReversed : card.title}
                text={isReversed ? card.keywordReversed : card.keywords}
                onClick={() => {
                  if (!isPremium) {
                    setShowLandingPage(true);
                  } else {
                    setSelectedTarotCard(card);
                  }
                }}
                image={`${API_URL}/api/images/tarotcards/${card.image}.jpg`}
                isImageReversed={isReversed}
                isTarotReading
              ></ContentCard>
            ))}
          </div>
        </>
      )}

      {selectedSpread && (
        <Modal
          onClose={() => {
            setSelectedSpread(null);
            getTodaysSpreads();
          }}
        >
          <TarotSpread
            spread={selectedSpread}
            tarotCards={tarotCards}
            onCardReveal={() => {
              getTodaysSpreads();
            }}
          ></TarotSpread>
        </Modal>
      )}

      {selectedArticle && (
        <Modal
          onClose={() => {
            setSelectedArticle(null);
          }}
        >
          <StoryPage
            title={selectedArticle.title}
            text={selectedArticle.content}
            handleClose={() => {
              setSelectedArticle(null);
            }}
          ></StoryPage>
        </Modal>
      )}

      {selectedTarotCard && (
        <Modal
          onClose={() => {
            setSelectedTarotCard(null);
          }}
        >
          <TarotDetailPage
            selectedCard={selectedTarotCard}
            reversed={isReversed}
            handleClose={() => {
              setSelectedTarotCard(null);
            }}
          ></TarotDetailPage>
        </Modal>
      )}

      {showLandingPage && (
        <Modal
          onClose={() => {
            setShowLandingPage(false);
          }}
        >
          <OnboardingPage
            onClose={() => setShowLandingPage(false)}
          ></OnboardingPage>
        </Modal>
      )}

      {selectedList && (
        <Modal
          onClose={() => {
            setSelectedList(null);
          }}
        >
          <TarotListPage
            title={selectedList.title}
            spread={selectedList.spread}
            tarotCards={selectedList.tarotCards}
          ></TarotListPage>
        </Modal>
      )}
    </StyledTarotPage>
  );
}
