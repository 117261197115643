import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
import CloseIcon from "./SVG/CloseIcon";
import { StyledElements } from "StyledElements";

// Keyframe animation for modal entry
const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

// Styled components for the modal and background
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  overflow: hidden;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  animation: ${({ shouldAnimateOut }) =>
      shouldAnimateOut ? slideOut : slideIn}
    0.5s ease;
  background: linear-gradient(130deg, #fff, #f8efea);
  color: #111;
  position: fixed;

  .react-modal-content {
    margin: 0 auto;
    max-width: 580px;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 90vh;
    padding-top: 0;
  }

  .modal-close {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 38px;
    height: 38px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
    transition: all 0.3s;

    svg {
      height: 30px;
      width: 30px;
      fill: #c19c7b;
      stroke-width: 48;
    }

    &:hover {
      transform: rotate(90deg);
    }

    @media (min-width: 720px) {
      width: 60px;
      height: 60px;
      top: 5%;
      right: 5%;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  .modal-back {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 38px;
    height: 38px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
    transition: all 0.3s;

    svg {
      height: 30px;
      width: 30px;
      fill: #c19c7b;
      stroke-width: 48;
    }

    &:hover {
      transform: rotate(90deg);
    }

    @media (min-width: 720px) {
      width: 60px;
      height: 60px;
      top: 5%;
      right: 5%;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
`;

// Modal component
const Modal = ({ onClose, children }) => {
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);
  const [portalRoot, setPortalRoot] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 10;

  useEffect(() => {
    const findShadowRoot = () => {
      // Find the div that has the shadow root
      const elements = Array.from(document.querySelectorAll("div"));
      const hostElement = elements.find((el) => el.shadowRoot);

      if (!hostElement) {
        if (retryCount < maxRetries) {
          setTimeout(() => {
            setRetryCount((prev) => prev + 1);
          }, 100);
          return;
        }
        console.error(
          "Could not find shadow root element after",
          maxRetries,
          "attempts",
        );
        return;
      }

      const shadowRoot = hostElement.shadowRoot;
      if (!shadowRoot) {
        if (retryCount < maxRetries) {
          setTimeout(() => {
            setRetryCount((prev) => prev + 1);
          }, 100);
          return;
        }
        console.error(
          "Could not find shadow root after",
          maxRetries,
          "attempts",
        );
        return;
      }

      // Create or find the portal container
      let container = shadowRoot.querySelector("#modal-root");
      if (!container) {
        container = document.createElement("div");
        container.id = "modal-root";
        shadowRoot.appendChild(container);
      }
      setPortalRoot(container);
    };

    findShadowRoot();

    // Prevent scrolling on the background content when the modal is open
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";

    const handlePopState = () => {
      handleClose();
    };

    const modalPushState = () => {
      window.history.pushState(null, null, null);
    };

    window.addEventListener("popstate", handlePopState);
    modalPushState();

    return () => {
      // Re-enable scrolling on the background content when the modal is closed
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
      window.removeEventListener("popstate", handlePopState);
    };
  }, [retryCount]);

  const handleClose = () => {
    setShouldAnimateOut(true);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  if (!portalRoot) return null;

  return createPortal(
    <StyledElements>
      <ModalBackground onClick={handleClose} data-id="screenshot-wrapper">
        <ModalContainer
          shouldAnimateOut={shouldAnimateOut}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="modal-close hide-to-share"
            onClick={() => {
              setShouldAnimateOut(true);
              setTimeout(() => {
                onClose();
              }, 500);
            }}
          >
            <CloseIcon />
          </div>
          <div className="react-modal-content" data-id="screenshot-area">
            {children}
          </div>
        </ModalContainer>
      </ModalBackground>
    </StyledElements>,
    portalRoot,
  );
};

export default Modal;
