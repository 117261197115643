import dayjs from "dayjs";
import { MOON_DATA_API } from "constants/URLS";

export const getMoonData = (selectedDate, language) => {
  const now = dayjs();
  let adjustedDate = dayjs(selectedDate);

  if (now.hour() < 9) {
    adjustedDate = adjustedDate.subtract(1, "days");
  }

  const moonDataUrl = `${MOON_DATA_API}?date=${adjustedDate.format(
    "D-M-YYYY",
  )}&language=${language}`;

  return fetch(moonDataUrl).then((response) => response.json());
};

export const prepareMoonData = (responseMoonData, responseMoonExplanation) => {
  let moonChangeTime = null;
  if (responseMoonData.zodiacChangeTimeUTC !== null) {
    moonChangeTime = dayjs
      .utc(responseMoonData.zodiacChangeTimeUTC)
      .tz(dayjs.tz.guess())
      .format("HH:mm");
  }

  let splittedDoItems = responseMoonExplanation[0].do
    .map((item) => {
      const matches = item.match(/<b>(.*?)<\/b>([^<]*)/);
      if (matches) {
        return {
          headline: matches[1].replace(":", "").trim(),
          text: matches[2].trim(),
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  const topicMap = {
    0: { topic: "Body care", icon: "💆‍♀️" },
    1: { topic: "Plants", icon: "🌿" },
    2: { topic: "Health", icon: "🧘‍♀️" },
    3: { topic: "Career", icon: "🤝" },
    4: { topic: "Travel", icon: "✈️" },
    5: { topic: "Spirituality", icon: "🧘" },
    6: { topic: "Big decisions", icon: "💡" },
    7: { topic: "Social activities", icon: "🎭" },
    8: { topic: "Nutrition", icon: "🥗" },
  };

  if (splittedDoItems && Array.isArray(splittedDoItems)) {
    splittedDoItems = splittedDoItems.map((item, index) => {
      if (item && !item.topic && topicMap[index]) {
        return {
          ...item,
          topic: encodeURIComponent(topicMap[index].topic),
          icon: topicMap[index].icon,
        };
      }
      return item;
    });
  }

  return {
    moonChangeTime,
    moonDoItems: splittedDoItems,
    selectedMoonDoItem: splittedDoItems[0],
  };
};
