import { BASE_URL } from "constants/URLS";
import React, { useState, useRef } from "react";

import styled from "styled-components";
import CheckmarkCircleIcon from "./SVG/CheckmarkCircleIcon";
import LockClosedIcon from "./SVG/LockClosedIcon";
import ChevronForwardIcon from "./SVG/ChevronForwardIcon";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledContentCard = styled.div`
  position: relative;
  margin: 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 1px solid whitesmoke;
  border-radius: 0;
  padding-bottom: 20px;
  margin: 20px 3% 20px;
  padding: 0 0 20px 0;
  max-width: 580px;
  cursor: pointer;
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;
  transition: all 0.3s ease;
  transform-style: preserve-3d;
  perspective: 1000px;
  touch-action: manipulation;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px 0px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    transition: 0.5s;
    z-index: 1;
  }

  &:hover::before {
    left: 100%;
  }

  .ripple {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.7);
    transform: scale(0);
    animation: ripple 0.6s linear;
    pointer-events: none;
    z-index: 2;
  }

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

  @media (min-width: 720px) {
    margin: 20px auto 20px;
  }

  &.is-white {
    background-color: #fff;
    box-shadow: #cccccc 0 0 16px;
    padding: 10px;
    border-radius: 10px;

    .content-card-text {
      padding: 0;
    }
  }

  .content-card-image {
    width: 60px;
    min-width: 60px;
    min-height: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 24px;
    position: relative;
    margin-right: 0;
    font-family: "Polarity-Regular", serif;
    background-color: #fff;
    border: 1px solid whitesmoke;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .content-card-circle-text {
    width: 60px;
    min-width: 60px;
    min-height: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 18px;
    position: relative;
    margin-right: 0;
    font-family: "Polarity-Regular", serif;
    background-color: #fff;
    border: 1px solid whitesmoke;
    border-radius: 10px;
    line-height: 1;
    text-align: center;
    transition: transform 0.3s ease;
    transform: translateZ(20px);

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .content-card-icon {
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
    transform: translateZ(15px);

    &:hover {
      transform: translateX(2px) translateZ(25px);
    }

    svg {
      width: 20px;
      margin: 0 auto;
      display: inline-block;
      fill: rgba(18, 18, 18, 0.75);
      color: rgba(18, 18, 18, 0.75);
      transition: all 0.3s ease;
    }
  }

  .content-card-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    width: 75%;
    line-height: 1.3;
    width: 100%;
    transform: translateZ(10px);

    h4 {
      font-size: 16px;
      margin: 0 0 4px;
      color: #111;
      line-height: 1.2;
      width: 100%;
      font-family: "Polarity-Regular", sans-serif;
      transition: color 0.3s ease;

      span {
        white-space: nowrap;
      }
    }

    p {
      margin: 0 0 0 0;
      font-size: 14px;
      color: #111;
      color: rgba(18, 18, 18, 0.75);
      font-family: Assistant, sans-serif;
      line-height: 1.4;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: color 0.3s ease;

      span {
        white-space: nowrap;
      }
    }

    .task-star {
      font-size: 12px;
      font-weight: 700;

      img {
        width: 10px;
      }
    }
  }

  &.is-rounded-image {
    .content-card-image {
      border-radius: 50%;
    }
  }

  &.is-tarot-reading {
    .content-card-image {
      background-image: none !important;
      background-size: 50% 50%;
      border: 0 none;
      border-radius: 10px;
    }
  }

  &.is-done {
    opacity: 0.1;

    .content-card-icon svg {
      fill: #0b7;
    }
  }

  &.is-tarot-reading {
    .content-card-image img {
      max-width: 35px;
      max-height: 100%;
    }
  }

  &:hover {
    .content-card-icon svg {
      fill: #c19c7b;
      color: #c19c7b;
    }
  }
`;

export default function ContentCard({
  title,
  text,
  isLocked = false,
  onClick,
  image,
  isImageReversed,
  isWhite = false,
  isRoundedImage = false,
  circleText = "",
  isTarotReading = false,
  isDone = false,
  isTask = false,
}) {
  const cardRef = useRef(null);
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [isTouch, setIsTouch] = useState(false);

  const handleMouseMove = (e) => {
    if (!cardRef.current || isTouch) return;

    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const rotateX = (y - centerY) / 20;
    const rotateY = (centerX - x) / 20;

    setRotation({ x: rotateX, y: rotateY });
  };

  const handleMouseLeave = () => {
    if (!isTouch) {
      setRotation({ x: 0, y: 0 });
    }
  };

  const createRipple = (event) => {
    if (!cardRef.current) return;

    const rect = cardRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const ripple = document.createElement("span");
    ripple.className = "ripple";
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;

    cardRef.current.appendChild(ripple);

    ripple.addEventListener("animationend", () => {
      ripple.remove();
    });
  };

  const handleTouchStart = (e) => {
    setIsTouch(true);
    createRipple(e.touches[0]);
  };

  const handleTouchMove = (e) => {
    if (!cardRef.current) return;

    const touch = e.touches[0];
    const rect = cardRef.current.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;

    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const rotateX = (y - centerY) / 20;
    const rotateY = (centerX - x) / 20;

    setRotation({ x: rotateX, y: rotateY });
  };

  const handleTouchEnd = () => {
    setIsTouch(false);
    setRotation({ x: 0, y: 0 });
  };

  return (
    <StyledContentCard
      ref={cardRef}
      onClick={onClick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{
        transform: `perspective(1000px) rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
      }}
      className={`content-card ${isLocked ? "is-locked" : ""} ${isWhite ? "is-white" : ""}  ${
        isRoundedImage ? "is-rounded-image" : ""
      } ${image ? "" : "no-image"} ${isTarotReading ? "is-tarot-reading" : ""} ${
        isDone ? "is-done" : ""
      }`}
    >
      {image ? (
        <div
          className="content-card-image"
          style={{
            transform: isImageReversed ? "rotate(180deg)" : "",
            backgroundImage: `url(${BASE_URL}/api/images/placeholder.jpg)`,
          }}
        >
          <LazyLoadImage
            effect="blur"
            wrapperProps={{
              style: { transitionDelay: "1s" },
            }}
            src={image}
          />
        </div>
      ) : (
        <div
          className="content-card-circle-text"
          dangerouslySetInnerHTML={{ __html: circleText }}
        ></div>
      )}

      <div className="content-card-text">
        <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
      {isTask ? (
        <div className="content-card-icon">
          {isDone ? (
            <CheckmarkCircleIcon />
          ) : (
            <>{isLocked ? <LockClosedIcon /> : <ChevronForwardIcon />}</>
          )}
        </div>
      ) : (
        <div className="content-card-icon">
          {isLocked ? <LockClosedIcon /> : <ChevronForwardIcon />}
        </div>
      )}
    </StyledContentCard>
  );
}
