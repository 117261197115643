import React from "react";

import styled from "styled-components";

const StyledSectionHeader = styled.div`
  position: relative;
  overflow: hidden;
  /*display: flex;
  align-items: center;
  justify-content: space-between;*/
  padding-top: 40px;
  max-width: 580px;
  max-width: 580px;
  text-align: center;

  line-height: 1.4;
  font-size: 20px;
  font-weight: 700;
  color: #111;
  text-align: center;
  font-family: "Polarity-Regular", serif;
  margin: 0 10% 20px;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }

  /*.is-left {
    margin-top: 20px;
    margin-left: 0;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      padding: 0;
      text-align: left;
      margin: 0;
    }
  }*/

  .no-spacing {
    h4 {
      margin: 0;
      padding: 0;
    }
  }

  .is-small {
    h4 {
      font-size: 14px;
      font-family: "Assistant", sans-serif;
    }
  }

  @media (min-width: 720px) {
    margin: 20px auto 20px;
  }

  &.section-header--has-subline {
    margin-bottom: 0;
  }

  &:first-of-type {
    border: 0 none;
    margin-top: 0;
  }

  b {
    font-weight: 600;
  }

  small {
    display: block;
    font-size: 15px;
    font-weight: 400;
    font-family: Assistant, sans-serif;
    line-height: 1.4;
    //color: #111;
    color: rgba(18, 18, 18, 0.75);
  }

  p {
    text-align: center;
    margin: 0;
    font-size: 14px;
    color: rgba(18, 18, 18, 0.75);
  }

  .section-header--show-more {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    color: #c19c7b;
    padding: 4px 10px;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 auto 1px;
    white-space: nowrap;
    background-color: rgba(193, 156, 123, 0.15) !important;
    color: #c19c7b;
    border: 0 none;

    span {
    }
  }
`;

export default function SectionHeader({
  title,
  text,
  isLeft,
  onShowMore,
  showMoreText,
  noSpacing = false,
  isSmall = false,
}) {
  function createMarkup() {
    return { __html: text };
  }

  return (
    <StyledSectionHeader>
      <div
        className={`section-header animated fadeIn ${isLeft ? "is-left" : ""} ${
          noSpacing ? "no-spacing" : ""
        } ${isSmall ? "is-small" : ""}`}
      >
        <span>{title}</span>
        {text && <small dangerouslySetInnerHTML={createMarkup()}></small>}

        <div className="section-header-divider">
          <svg
            width="42"
            height="6"
            viewBox="0 0 42 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.29067 2.60873C1.30745 1.43136 2.72825 0.72982 4.24924 0.700808C5.77022 0.671796 7.21674 1.31864 8.27768 2.45638C8.97697 3.20628 9.88872 3.59378 10.8053 3.5763C11.7218 3.55882 12.6181 3.13683 13.2883 2.36081C14.3051 1.18344 15.7259 0.481897 17.2469 0.452885C18.7679 0.423873 20.2144 1.07072 21.2753 2.20846C21.9746 2.95836 22.8864 3.34586 23.8029 3.32838C24.7182 3.31092 25.6133 2.89009 26.2831 2.11613C26.2841 2.11505 26.285 2.11396 26.2859 2.11288C27.3027 0.935512 28.7235 0.233974 30.2445 0.204962C31.7655 0.17595 33.212 0.822796 34.2729 1.96053C34.9722 2.71044 35.884 3.09794 36.8005 3.08045C37.7171 3.06297 38.6134 2.64098 39.2836 1.86496C39.6445 1.44697 40.276 1.40075 40.694 1.76173C41.112 2.12271 41.1582 2.75418 40.7972 3.17217C39.7804 4.34954 38.3597 5.05108 36.8387 5.08009C35.3177 5.1091 33.8712 4.46226 32.8102 3.32452C32.1109 2.57462 31.1992 2.18712 30.2826 2.2046C29.3674 2.22206 28.4723 2.64289 27.8024 3.41684C27.8015 3.41793 27.8005 3.41901 27.7996 3.42009C26.7828 4.59746 25.362 5.299 23.841 5.32801C22.3201 5.35703 20.8735 4.71018 19.8126 3.57244C19.1133 2.82254 18.2016 2.43504 17.285 2.45252C16.3685 2.47 15.4722 2.89199 14.802 3.66802C13.7852 4.84539 12.3644 5.54693 10.8434 5.57594C9.32242 5.60495 7.8759 4.9581 6.81496 3.82037C6.11568 3.07046 5.20392 2.68296 4.28738 2.70044C3.37083 2.71793 2.47452 3.13992 1.80434 3.91594C1.44336 4.33393 0.811887 4.38015 0.393899 4.01917C-0.0240897 3.65819 -0.0703068 3.02672 0.29067 2.60873Z"
              fill="#c19c7b"
            ></path>
          </svg>
        </div>

        {onShowMore && (
          <div className="section-header--show-more" onClick={onShowMore}>
            {showMoreText}
          </div>
        )}
      </div>
    </StyledSectionHeader>
  );
}
