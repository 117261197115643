import styled from "styled-components";

export const StyledDesktopNavigation = styled.div`
  position: relative;

  .login-information {
    margin-bottom: 0;
    overflow: hidden;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 30;
    padding-bottom: 20px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    background-color: #fff;
  }

  .navigation-wrapper {
    margin-bottom: 0;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 30;
    padding-bottom: 20px;
    background-color: #fff;
    border-top-left-radius: 10px 10px;
    border-top-right-radius: 10px 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    background-color: #fff;

    /*@media (min-width: 768px) {
      border-top: 1px solid whitesmoke;
      position: sticky;
      top: 150px;
      border-top-left-radius: 0 0;
      border-top-right-radius: 0 0;
      padding: 10px;
    }*/

    .sidenav {
      max-width: 740px;
      margin: 0 auto;
      padding: 0;
      display: flex;

      li {
        list-style: none;
        padding: 10px 0;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #8d8d8d;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
        transition: all 0.1s ease-in;
        font-weight: 700;
        color: #ddd;
        line-height: 1.2;
        font-family: "Assistant", sans-serif;
        margin: 0;

        &:last-child {
          margin-right: 0;
        }

        svg {
          width: 20px;
          height: 20px;
          margin-bottom: 4px;
          fill: #ddd;
        }

        &:hover {
        }

        &.is-active {
          color: #111;

          svg {
            fill: #111;
          }
        }
      }
    }

    /*@media (min-width: 768px) {
    position: initial;
    padding: 0 3%;
    margin-bottom: 20px;
    box-shadow: none;
    border: 0 none;
    overflow: visible;

    .sidenav {
      margin: 0;
      padding: 0;
      display: flex;

      li {
        list-style: none;
        padding: 18px 20px;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #111;
        border-radius: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        margin-right: 20px;
        transition: all 0.1s ease-in;
        //box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
        flex-direction: row;
        border: 1px solid #eee;
        white-space: nowrap;

        &:last-child {
          margin-right: 0;
        }

        svg {
          margin-right: 16px;
          width: 20px;
          height: 20px;
          margin-bottom: 0;
        }

        &:hover {
          background-color: #111;
          border: 1px solid #111;
          color: #fff;
        }

        &.is-active {
          background-color: #111;
          border: 1px solid #111;
          color: #fff;

          svg {
            fill: #fff;
          }
        }
      }
    }
  }*/
  }
`;
