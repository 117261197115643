import React, { useState, lazy, Suspense, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAPI } from "context/APIcontext";
import PageTitle from "components/PageTitle";
import SectionHeader from "components/SectionHeader";
import BlockButton from "components/buttons/BlockButton";
import LoadingState from "components/LoadingState";
import Modal from "components/Modal";
import ContentCard from "components/ContentCard";
import StatsCard from "components/StatsCard";
import StatsCardLoading from "components/StatsCardLoading";
import { API_URL, BIRTHCHART_API, CHART_EXPLANATION_API } from "constants/URLS";
import * as animationData from "assets/lottie/animation_onboarding_gender.json";
import Lottie from "react-lottie";
import convertToDecimalCoordinates from "helper/convertToDecimalCoordinates";
import { getLanguage } from "helper/getLanguage";
import {
  calculateDominantElement,
  calculateDominantModality,
  getMoonPhase,
  getMoonSign,
  getRisingSign,
  getSunSign,
} from "helper/astroHelper";
import AstrologyTable from "components/AstrologyTable";
import AstrologyInsights from "components/AstrologyInsights";
import BirthChartDiagram from "components/BirthChartDiagram";
import { useCoachingOptions } from "constants/COACHING_OPTIONS";
import dayjs from "dayjs";
import ChevronForwardIcon from "components/SVG/ChevronForwardIcon";
import BirthchartPage from "pages/BirthchartPage/BirthchartPage";
import StoryPage from "pages/StoryPage/StoryPage";
import { StyledProfilePage } from "components/ProfilePage/StyledProfilePage";

const OnboardingPage = lazy(
  () => import("pages/OnboardingPage/OnboardingPage"),
);
const NameStep = lazy(() => import("components/OnboardingSteps/NameStep"));
const BirthdayDateStep = lazy(
  () => import("components/OnboardingSteps/BirthdayDateStep"),
);
const BirthTimeStep = lazy(
  () => import("components/OnboardingSteps/BirthTimeStep"),
);
const CoachingAreasStep = lazy(
  () => import("components/OnboardingSteps/CoachingAreasStep"),
);

export default function ProfilePage() {
  const { t } = useTranslation("common");
  const myRef = useRef(null);
  const {
    deleteTasks,
    userLoggedIn,
    setUserLoggedIn,
    userData,
    saveUserData,
    isPremium,
  } = useAPI();

  const coachingOptions = useCoachingOptions();

  const [showOnboarding, setShowOnboarding] = useState(false);
  const [selectedExplanation, setSelectedExplanation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditName, setShowEditName] = useState(false);
  const [showEditBirthDay, setShowEditBirthDay] = useState(false);
  const [showEditBirthTime, setShowEditBirthTime] = useState(false);
  const [showEditBirthLocation, setShowEditBirthLocation] = useState(false);
  const [userProfile, setUserProfile] = useState(userData?.userProfile);
  const [showBirthChartDetail, setShowBirthChartDetail] = useState(false);
  const [showEditCoachingAreas, setShowEditCoachingAreas] = useState(false);

  const [selectedCoachingAreas, setSelectedCoachingAreas] = useState([]);

  const [birthChart, setBirthChart] = useState(null);
  const [dominantElement, setDominantElement] = useState(null);
  const [dominantModality, setDominantModality] = useState(null);
  const [moonPhase, setMoonPhase] = useState(null);
  const [risingSign, setRisingSign] = useState(null);
  const [sunSign, setSunSign] = useState(null);
  const [moonSign, setMoonSign] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (
          userLoggedIn &&
          userData?.userProfile &&
          userData.userProfile.location
        ) {
          getBirthchart(userData?.userProfile);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userLoggedIn, userData]);

  useEffect(() => {
    if (userData?.userProfile) {
      setUserProfile(userData.userProfile);
    }
  }, [userData]);

  async function logout() {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userData");
    setUserLoggedIn(false);
    setUserProfile(null);
    window.location.reload();
  }

  const getBirthchart = async (profile) => {
    console.log(profile);
    const cords = convertToDecimalCoordinates(profile);

    let brithChartAPI = `${BIRTHCHART_API}?language=${getLanguage()}&day=${
      profile.birthDay
    }&month=${profile.birthMonth}&year=${profile.birthYear}&minutes=${
      profile.birthMinute || "00"
    }&hours=${profile.birthHour || "00"}&lat=${cords.decimalLatitude}&lang=${
      cords.decimalLongitude
    }`;

    try {
      const response = await fetch(brithChartAPI);
      const data = await response.json();

      const simpleBirthchart = {
        objects: { ...data.birthchart.objects },
        weightings: { ...data.birthchart.weightings },
        houses: { ...data.birthchart.houses },
        moon_phase: { ...data.birthchart.moon_phase },
      };
      console.log(simpleBirthchart);

      console.log(simpleBirthchart);

      setDominantElement(
        calculateDominantElement(simpleBirthchart.weightings.elements),
      );
      setDominantModality(
        calculateDominantModality(simpleBirthchart.weightings.modalities),
      );
      setMoonPhase(getMoonPhase(simpleBirthchart.moon_phase));
      setRisingSign(getRisingSign(simpleBirthchart.objects));
      setSunSign(getSunSign(simpleBirthchart.objects));
      setMoonSign(getMoonSign(simpleBirthchart.objects));

      setBirthChart(simpleBirthchart);
    } catch (error) {
      return [];
    }
  };

  async function getStory(title) {
    const explanations = await getBirthChartExplanations([title]);
    const explanation = explanations[0];
    setSelectedExplanation(explanation);
  }

  function getBirthChartExplanations(titles) {
    setIsLoading(true);

    let requests = titles.map((title) => {
      let birthChartAPI = `${CHART_EXPLANATION_API}?titles[]=${encodeURIComponent(
        title,
      )}&chartType=birthchart&isMe=true&language=${getLanguage()}`;
      return fetch(birthChartAPI).then((response) => response.json());
    });

    return Promise.all(requests).then((responses) => {
      // Use reduce and concat to merge all response arrays into one big array
      setIsLoading(false);
      return responses.reduce((acc, val) => acc.concat(val), []);
    });
  }

  function scrollToWidget() {
    //myRef.current.scrollIntoView()
  }

  async function updateUserData(key, value) {
    const updatedUserData = { ...userData };
    updatedUserData.userProfile[key] = value;
    setUserProfile(updatedUserData.userProfile);
    saveUserData(updatedUserData);
  }

  return (
    <StyledProfilePage>
      {userProfile && userLoggedIn ? (
        <div className="user-logged-in animated fadeIn">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={200}
            width={200}
          />

          {/*birthChart && (
            <div className="profile-header-wrapper">
              <div className="profile-name">Marcus Graf</div>

              <div className="profile-birthday">May 24, 1987 - 02:34</div>

              <div className="profile-header-image-wrapper">
                <img
                  className="profile-header-image"
                  src={`${API_URL}/api/images/watercolor/${
                    "zodiac-" +
                    userProfile.zodiacSign.toLowerCase().replace(/ /g, "_")
                  }.jpg`}
                />
              </div>

              <div className="profile-props">
                <div className="profile-props-dominant-element">
                  {t(dominantElement)}
                  <strong>{t("dominant-element")}</strong>
                </div>
                <div className="profile-props-dominant-modality">
                  {t(dominantModality)}
                  <strong>{t("dominant-modality")}</strong>
                </div>
                <div className="profile-props-moon-phase">
                  {t(moonPhase)}
                  <strong>{t("moon-phase")}</strong>
                </div>
                <div className="profile-props-rising-sign">
                  {t(risingSign)}
                  <strong>{t("rising-sign")}</strong>
                </div>
                <div className="profile-props-sun-sign">
                  {t(sunSign)}
                  <strong>{t("sun-sign")}</strong>
                </div>
                <div className="profile-props-moon-sign">
                  {t(moonSign)}
                  <strong>{t("moon-sign")}</strong>
                </div>
              </div>
            </div>
                )*/}

          {birthChart && (
            <>
              {/*
              <BirthChartDiagram birthChart={birthChart}></BirthChartDiagram>

              <AstrologyInsights birthChart={birthChart}></AstrologyInsights>

              <AstrologyTable
                birthChart={birthChart}
                onRowClick={(e) => {
                  console.log(e)
                }}
              ></AstrologyTable>

              <p>
                <strong>🌟 Dominant Element:</strong> {dominantElement}
              </p>
              <p>
                <strong>🎭 Dominant Modality:</strong> {dominantModality}
              </p>
              <p>
                <strong>🧭 Moon Phase:</strong> {moonPhase}
              </p>
              <p>
                <strong>⬆️ Rising Sign:</strong> {risingSign}
              </p>
              <p>
                <strong>🌞 Sun Sign:</strong> {sunSign}
              </p>
              <p>
                <strong>🌙 Moon Sign:</strong> {moonSign}
              </p>

              */}
            </>
          )}

          <PageTitle
            title={`${userProfile.firstName} ${userProfile.lastName}`}
            text={`${userProfile.birthDay}. ${t(dayjs(`${userProfile.birthYear}-${userProfile.birthMonth}-${userProfile.birthDay}`).format("MMMM").toLowerCase())} ${userProfile.birthYear} - ${t(userProfile.zodiacSign)}`}
          />

          {birthChart && (
            <>
              <div className="stats-card-wrapper">
                {isLoading ? (
                  <>
                    <StatsCardLoading />
                    <StatsCardLoading />
                    <StatsCardLoading />
                    <StatsCardLoading />
                    <StatsCardLoading />
                    <StatsCardLoading />
                  </>
                ) : (
                  <>
                    <StatsCard
                      image={`${API_URL}/api/images/watercolor/astrology-${sunSign.toLowerCase()}.jpg`}
                      label={t("sun-sign")}
                      value={t(sunSign.toLowerCase())}
                      onClick={() =>
                        getStory(`sun sign is ${sunSign.toLowerCase()}`)
                      }
                    />
                    <StatsCard
                      image={`${API_URL}/api/images/watercolor/astrology-${moonSign.toLowerCase()}.jpg`}
                      label={t("moon-sign")}
                      value={t(moonSign.toLowerCase())}
                      onClick={() =>
                        getStory(`moon sign is ${moonSign.toLowerCase()}`)
                      }
                    />
                    <StatsCard
                      image={`${API_URL}/api/images/watercolor/astrology-${dominantElement.toLowerCase()}.jpg`}
                      label={t("dominant-element")}
                      value={t(dominantElement.toLowerCase())}
                      onClick={() =>
                        getStory(
                          `dominant element is ${dominantElement.toLowerCase()}`,
                        )
                      }
                    />
                    <StatsCard
                      image={`${API_URL}/api/images/watercolor/astrology-${dominantModality.toLowerCase()}.jpg`}
                      label={t("dominant-modality")}
                      value={t(dominantModality.toLowerCase())}
                      onClick={() =>
                        getStory(
                          `dominant modality is ${dominantModality.toLowerCase()}`,
                        )
                      }
                    />
                    <StatsCard
                      image={`${API_URL}/api/images/watercolor/astrology-${risingSign.toLowerCase()}.jpg`}
                      label={t("rising-sign")}
                      value={t(risingSign.toLowerCase())}
                      onClick={() =>
                        getStory(`rising sign is ${risingSign.toLowerCase()}`)
                      }
                    />
                    <StatsCard
                      image={`${API_URL}/api/images/watercolor/astrology-${moonPhase.toLowerCase().replace(/ /g, "-")}.jpg`}
                      label={t("moon-phase")}
                      value={t(moonPhase.toLowerCase())}
                      onClick={() =>
                        getStory(`moon phase is ${moonPhase.toLowerCase()}`)
                      }
                    />
                  </>
                )}
              </div>
            </>
          )}

          <div className="astrology-profile"></div>
          <SectionHeader title={t("detailed-insights")}></SectionHeader>
          <div className="">
            <ContentCard
              image={`${API_URL}/api/images/watercolor/my-birthchart.jpg`}
              title={`${t("birthchart-headline")}`}
              text={`${t("birthchart-subheadline")}`}
              onClick={() => {
                if (userLoggedIn && isPremium) {
                  setShowBirthChartDetail(userData.userProfile);
                } else {
                  setShowOnboarding(true);
                }
              }}
              isLocked={!userLoggedIn || !isPremium}
            ></ContentCard>
          </div>
          <SectionHeader
            title={t("profile-page.edit-coaching-headline")}
          ></SectionHeader>

          <div className="tag-cloud">
            {userData?.userProfile?.selectedCoachingAreas?.map(
              (coachingArea, index) => {
                const option = coachingOptions.find(
                  (option) => option.value === coachingArea,
                );
                return (
                  <div key={`coachingArea-${index}`} className="tag">
                    {option ? (
                      <>
                        <span className="tag-icon">{option.icon}</span>
                        {option.label}
                      </>
                    ) : (
                      coachingArea // Fallback in case the value isn't found
                    )}
                  </div>
                );
              },
            )}
          </div>

          <BlockButton
            noBorder
            isOutlined
            onClick={() => setShowEditCoachingAreas(true)}
            text={t("profile-page.cta-edit-coaching-areas")}
          ></BlockButton>

          <SectionHeader
            title={t("profile-page.edit-profil-headline")}
          ></SectionHeader>
          <div className="profile-block-wrapper">
            <div
              className="profile-block"
              onClick={() => setShowEditName(true)}
            >
              <div className="profile-block-left">{t("firstname")}</div>
              <div className="profile-block-right">
                {userData?.userProfile.firstName}
              </div>
            </div>
            <div
              className="profile-block"
              onClick={() => setShowEditName(true)}
            >
              <div className="profile-block-left">{t("lastname")}</div>
              <div className="profile-block-right">
                {userData?.userProfile.lastName}
              </div>
            </div>
            <div
              className="profile-block"
              onClick={() => setShowEditBirthDay(true)}
            >
              <div className="profile-block-left">{t("birthday")}</div>
              <div className="profile-block-right">
                {userData?.userProfile.birthDay}.
                {userData?.userProfile.birthMonth}.
                {userData?.userProfile.birthYear}
              </div>
            </div>
            <div
              className="profile-block"
              onClick={() => setShowEditBirthTime(true)}
            >
              <div className="profile-block-left">{t("birthtime")}</div>
              <div className="profile-block-right">
                {userData?.userProfile.birthHours ? (
                  <>
                    {userData?.userProfile.birthHours}:
                    {userData?.userProfile.birthMinutes}
                  </>
                ) : (
                  <>{t("profile-page.not-set")}</>
                )}
              </div>
            </div>
          </div>
          <BlockButton
            noBorder
            isOutlined
            onClick={() => logout()}
            text={t("profile-page.cta-logout")}
          ></BlockButton>
          <BlockButton
            noBorder
            isOutlined
            onClick={() => deleteTasks()}
            text={t("profile-page.cta-delete-tasks")}
          ></BlockButton>
        </div>
      ) : (
        <div className="no-account">
          <LoadingState
            title={t("profile-page.no-profile-headline")}
            text={t("profile-page.no-profile-text")}
          ></LoadingState>

          <BlockButton
            isBottomSecond
            onClick={() => setShowOnboarding(true)}
            text={t("profile-page.cta-create-profile")}
          ></BlockButton>
        </div>
      )}

      {showEditCoachingAreas && (
        <Modal
          onClose={() => {
            setShowEditCoachingAreas(false);
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <CoachingAreasStep
              currentStep={0}
              setCurrentStep={() => {
                setShowEditCoachingAreas(false);
              }}
              scrollToWidget={scrollToWidget}
              selectedCoachingAreas={
                userData?.userProfile.selectedCoachingAreas
              }
              setSelectedCoachingAreas={(selectedCoachingAreas) => {
                setSelectedCoachingAreas(selectedCoachingAreas);
                updateUserData("selectedCoachingAreas", selectedCoachingAreas);
              }}
            />
          </Suspense>
        </Modal>
      )}

      {showOnboarding && (
        <Modal
          onClose={() => {
            setShowOnboarding(false);
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <OnboardingPage
              onClose={() => {
                setShowOnboarding(false);
              }}
            />
          </Suspense>
        </Modal>
      )}

      {showEditName && (
        <Modal
          onClose={() => {
            setShowEditName(false);
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <NameStep
              scrollToWidget={scrollToWidget}
              firstName={userProfile.firstName || ""}
              setFirstName={(firstName) => {
                updateUserData("firstName", firstName);
              }}
              lastName={userProfile.lastName || ""}
              setLastName={(lastName) => {
                updateUserData("lastName", lastName);
              }}
              setCurrentStep={() => {
                setShowEditName(false);
                updateUserData("lastName", userProfile.lastName);
              }}
            />
          </Suspense>
        </Modal>
      )}

      {showEditBirthDay && (
        <Modal
          onClose={() => {
            setShowEditBirthDay(false);
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <BirthdayDateStep
              scrollToWidget={scrollToWidget}
              day={userProfile.birthDay}
              setDay={(day) => {
                updateUserData("birthDay", day);
                setShowEditBirthDay(false);
              }}
              month={userProfile.birthMonth}
              setMonth={(month) => {
                updateUserData("birthMonth", month);
                setShowEditBirthDay(false);
              }}
              year={userProfile.birthYear}
              setYear={(year) => {
                updateUserData("birthYear", year);
                setShowEditBirthDay(false);
              }}
              onClose={() => {
                setShowEditBirthDay(false);
              }}
            />
          </Suspense>
        </Modal>
      )}

      {isLoading && (
        <Modal
          onClose={() => {
            setIsLoading(false);
          }}
        >
          <LoadingState
            title={t("article-page.transit-loading-headline")}
            text={t("article-page.transit-loading-text")}
          ></LoadingState>
        </Modal>
      )}

      {selectedExplanation && (
        <Modal
          onClose={() => {
            setSelectedExplanation(null);
          }}
        >
          <StoryPage
            title={selectedExplanation.title}
            text={selectedExplanation.text}
            handleClose={() => {
              setSelectedExplanation(null);
            }}
          ></StoryPage>
        </Modal>
      )}

      {showBirthChartDetail && (
        <Modal
          onClose={() => {
            setShowBirthChartDetail(null);
          }}
        >
          <BirthchartPage
            selectedUserData={showBirthChartDetail}
            goBack={() => {
              setShowBirthChartDetail(null);
            }}
          ></BirthchartPage>
        </Modal>
      )}

      {showEditBirthTime && (
        <Modal
          onClose={() => {
            setShowEditBirthTime(false);
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <BirthTimeStep
              scrollToWidget={scrollToWidget}
              hours={userProfile.birthHours}
              timeKnown={userProfile.knowExactTime}
              setHours={(hours) => {
                updateUserData("birthHours", hours);
              }}
              minutes={userProfile.birthMinutes}
              setMinutes={(minutes) => {
                updateUserData("birthMinutes", minutes);
              }}
              setTimeKnown={(timeKnown) => {
                updateUserData("knowExactTime", timeKnown);
              }}
              setCurrentStep={() => {
                setShowEditBirthTime(false);
              }}
            />
          </Suspense>
        </Modal>
      )}
    </StyledProfilePage>
  );
}
