import React from "react";
import ContentCard from "components/ContentCard";
import ContentCardLoading from "components/ContentCardLoading";
import { API_URL } from "constants/URLS";

const TaskList = React.memo(
  ({ tasks, onTaskClick, isLoading, selectedDate, userLoggedIn }) => {
    if (isLoading) {
      return (
        <>
          <ContentCardLoading />
          <ContentCardLoading />
          <ContentCardLoading />
          <ContentCardLoading />
        </>
      );
    }

    return (
      <>
        {tasks.map((task, index) => (
          <ContentCard
            key={`task-${index}-${task.title}`}
            title={task.title}
            text={task.intro}
            topic={task.topic}
            image={`${API_URL}/api/images/watercolor/${task.topic
              .toLowerCase()
              .replace(/ /g, "_")}.jpg`}
            onClick={() => onTaskClick(task, index)}
            isDone={task.isDone}
            action={task.action}
            isTask
            isLocked={task.acountNeeded && !userLoggedIn}
          />
        ))}
      </>
    );
  },
);

export default TaskList;
