import React, { useState, useRef } from "react";
import styled from "styled-components";
import ChevronForwardIcon from "./SVG/ChevronForwardIcon";

const StyledStatsCard = styled.div`
  position: relative;
  margin: 0;
  display: flex;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 1px solid whitesmoke;
  border-radius: 0;
  padding-bottom: 20px;
  padding: 0 0 20px 0;
  cursor: pointer;
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;
  transition: all 0.3s ease;
  transform-style: preserve-3d;
  perspective: 1000px;
  will-change: transform;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px 0px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    transition: 0.5s;
    z-index: 1;
  }

  &:hover::before {
    left: 100%;
  }

  .stats-card-image {
    width: 40px;
    min-width: 40px;
    min-height: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 24px;
    position: relative;
    margin-right: 0;
    font-family: "Polarity-Regular", serif;
    background-color: #fff;
    border: 1px solid whitesmoke;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    transform: translateZ(20px);
    will-change: transform;
  }

  .stats-card-text {
    margin: 0 0 0 10px;
    overflow: hidden;
    width: 100%;
    transform: translateZ(10px);
    will-change: transform;

    .stats-card-label {
      font-size: 14px;
      margin: 0 0 4px;
      color: #111;
      font-weight: 700;
      line-height: 1.2;
      width: 100%;
      font-family: Assistant, sans-serif;
      transition: color 0.3s ease;
    }

    .stats-card-value {
      font-size: 14px;
      color: #111;
      color: rgba(18, 18, 18, 0.75);
      font-family: Assistant, sans-serif;
      line-height: 1.4;
      transition: color 0.3s ease;
    }
  }

  .stats-card-icon {
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
    transform: translateZ(15px);
    will-change: transform;

    &:hover {
      transform: translateX(2px) translateZ(25px);
    }

    svg {
      width: 20px;
      margin: 0 auto;
      display: inline-block;
      fill: rgba(18, 18, 18, 0.75);
      color: rgba(18, 18, 18, 0.75);
      transition: all 0.3s ease;
    }
  }

  &:hover {
    .stats-card-label {
      color: #c19c7b;
    }
    .stats-card-value {
      color: #c19c7b;
    }
    .stats-card-icon svg {
      fill: #c19c7b;
      color: #c19c7b;
    }
  }
`;

export default function StatsCard({ image, label, value, onClick }) {
  const cardRef = useRef(null);
  const [rotation, setRotation] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    if (!cardRef.current) return;

    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const rotateX = (y - centerY) / 20;
    const rotateY = (centerX - x) / 20;

    setRotation({ x: rotateX, y: rotateY });
  };

  const handleMouseLeave = () => {
    setRotation({ x: 0, y: 0 });
  };

  return (
    <StyledStatsCard
      ref={cardRef}
      onClick={onClick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        transform: `perspective(1000px) rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
      }}
    >
      <div
        className="stats-card-image"
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <div className="stats-card-text">
        <div className="stats-card-label">{label}</div>
        <div className="stats-card-value">{value}</div>
      </div>
      <div className="stats-card-icon">
        <ChevronForwardIcon />
      </div>
    </StyledStatsCard>
  );
}
