import React, { useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import Lottie from "react-lottie";
import * as animationData from "assets/lottie/animation_onboarding_benefits.json";
import * as featuresAnimationData from "assets/lottie/animation_onboarding_tags.json";
import * as premium from "assets/lottie/animation_onboarding_premium.json";
import BlockButton from "components/buttons/BlockButton";
import { BASE_URL } from "constants/URLS";
import styled from "styled-components";
import StarIcon from "components/SVG/StarIcon";
import CheckmarkIcon from "components/SVG/CheckmarkIcon";

const StyledWelcomeStep = styled.div`
  padding-bottom: 140px;

  .onboarding-logo {
    margin: 10px 0 0;
    text-align: center;

    img {
      width: 120px;
    }
  }

  .feature-box {
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 10px;
    margin: 20px 3% 20px;
    padding-bottom: 20px;
  }

  ul.icon-list {
    margin: 0 3%;
    list-style: none;

    li {
      display: flex;
      font-size: 16px;

      svg {
        width: 30px;
        height: 30px;
        fill: #0b7;
        margin-right: 6px;
      }
    }
  }

  ul.box-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    margin: 0 3%;

    li {
      line-height: 1.4;
      display: flex;
      padding: 16px 12px 20px;
      flex-direction: column;
      border-radius: 10px;
      background: whitesmoke;
      white-space: pre-line;
      color: #7f89a5;
      font-size: 14px;

      span {
        width: 60px;
        height: 60px;
        background-color: #ffdb00;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        font-size: 22px;
      }

      b {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.4;
        color: #111;
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 1.2;
        font-family: "Assistant", sans-serif;
      }

      svg {
        fill: #111;
      }
    }
  }

  .is-outline {
    margin: 0;
    padding: 0;
  }
`;

export default function WelcomeStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  handleGoToLogin,
}) {
  const { t } = useTranslation("common");

  return (
    <StyledWelcomeStep>
      <div className="animated fadeIn">
        <div className="onboarding-logo">
          <img
            src="https://tinyaffirmation.com/wp-content/uploads/2024/03/tinyaffirmation-logo-gold.png"
            alt="Site Logotype"
          />
        </div>

        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: premium,
            rendererSettings: {
              preserveAspectRatio: "slice",
            },
          }}
          height={200}
          width={200}
        />

        <span className="onboarding-headline">
          {t("welcome-page.step-0-h1")}
        </span>
        <span className="onboarding-text">{t("welcome-page.step-0-span")}</span>
        {/*<BlockButton
          text={t('welcome-page.start-free-trial')}
          onClick={() => {
            scrollToWidget();
            setCurrentStep(currentStep + 1);
          }}
        ></BlockButton>
        <BlockButton
          isOutlined
          noBorder
          text={t('welcome-page.already-have-account')}
          onClick={() => {
            window.location.href = `${BASE_URL}/login/`;
          }}
        ></BlockButton>
        <div className="feature-box">
          <span className="onboarding-smallline">{t('features.what-you-get')}</span>
          <ul className="icon-list">
            <li>
              <StarIcon />
              <span>{t('features.feature-short-1')}</span>
            </li>
            <li>
              <StarIcon />
              <span>{t('features.feature-short-2')}</span>
            </li>
            <li>
              <StarIcon />
              <span>{t('features.feature-short-3')}</span>
            </li>
            <li>
              <StarIcon />
              <span>{t('features.feature-short-4')}</span>
            </li>
            <li>
              <StarIcon />
              <span>{t('features.feature-short-5')}</span>
            </li>
          </ul>
        </div>

        <div className="feature-box">
          <span className="onboarding-smallline">{t('features.we-will-help-you-with')}</span>
          <ul className="box-list">
            <li>
              <span>
                <CheckmarkIcon />
              </span>{' '}
              <b>{t('features.results-1-headline')}</b> {t('features.results-1-text')}.
            </li>
            <li>
              <span>
                <CheckmarkIcon />
              </span>{' '}
              <b>{t('features.results-2-headline')}</b> {t('features.results-2-text')}.
            </li>
            <li>
              <span>
                <CheckmarkIcon />
              </span>{' '}
              <b>{t('features.results-3-headline')}</b> {t('features.results-3-text')}.
            </li>
            <li>
              <span>
                <CheckmarkIcon />
              </span>{' '}
              <b>{t('features.results-4-headline')}</b> {t('features.results-4-text')}.
            </li>

            <li>
              <span>🔮</span> <b>Personalized Astrological Guidance:</b> Receive daily, weekly, and
              monthly horoscopes based on your birth chart or lunar phases.
            </li>
            <li>
              <span>🧘‍♀️</span> <b>Spiritual Practices & Tools:</b> Access guided meditations,
              mantras, runes, and tarot readings to enhance your emotional and spiritual well-being.
            </li>
            <li>
              <span>🧠</span> <b>Mental Health Coaching:</b> Engage with psychology and
              neuroscience-based coaching sessions that focus on stress reduction and building
              positive habits.
            </li>
            <li>
              <span>📝</span> <b>Self-Reflection & Journaling:</b> Use journaling exercises to
              process emotions, track progress, and deepen self-awareness.
            </li>
            <li>
              <span>🌱</span> <b>Holistic Well-being:</b> Combine breathwork, cognitive reframing,
              and emotional processing to foster overall mental health and resilience.
            </li>
            <li>
              <span>📅</span> <b>Routine, Habit Formation & Tarot:</b> Build daily routines and
              healthy habits with structured coaching techniques and tarot guidance for deeper
              self-reflection.
            </li>
          </ul>
        </div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: premium,
            rendererSettings: {
              preserveAspectRatio: 'slice',
            },
          }}
          height={150}
          width={150}
          style={{
            margin: '30px 3% 20px',
            paddingTop: '30px',
            borderTop: '1px solid #ddd',
            width: '94%',
          }}
        />
        <span className="onboarding-smallline">{t("Trusted by World's leading media")}</span>
        
      */}
      </div>

      <div className="footer-button-area">
        <div className="footer-button-area-inner">
          <BlockButton
            isOutlined
            isTextOnly
            text={t("welcome-page.already-have-account")}
            onClick={() => {
              handleGoToLogin();
              //window.location.href = `${BASE_URL}/login/`;
            }}
          ></BlockButton>

          <BlockButton
            text={t("continue")}
            onClick={() => {
              scrollToWidget();
              setCurrentStep(currentStep + 1);
            }}
          ></BlockButton>
        </div>
      </div>
    </StyledWelcomeStep>
  );
}
