import BlockButton from "components/buttons/BlockButton";
import ChevronBackIcon from "components/SVG/ChevronBackIcon";
import ChevronForwardIcon from "components/SVG/ChevronForwardIcon";
import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

const StyledHomePageTitle = styled.div`
  //font-family: "Libre Baskerville", serif;
  font-family: "Polarity-Regular", serif;
  //font-family: "breathing", sans-serif;
  line-height: 1 !important;
  font-size: 40px;
  font-weight: 700 !important;
  color: #111;
  text-align: center;
  position: relative;
  margin: 20px 3% 0;
  //color: #c19c7b;
  max-width: 580px;
  margin-bottom: 20px;
  max-width: 400px;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }

  ng-lottie {
    margin: 0 auto 20px;
    text-align: center;

    div {
      margin: 0 auto;
      text-align: center;
    }
  }

  .stats.overlay {
  }

  .page-title-big {
    margin-bottom: 6px;
  }

  .page-title-small {
    font-size: 18px;
    font-weight: 400;
    font-family: Assistant, sans-serif;
    line-height: 1.4;
    //color: #111;
    //min-height: 36px;
    color: rgba(18, 18, 18, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .day-change-button {
    padding: 0;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    font-family: "Polarity-Regular", serif;
    font-size: 14px;
    //background-color: rgba(193, 156, 123, 0.15) !important;
    color: #c19c7b;
    //padding: 8px 10px;
    width: 30px;
    top: -2px;
    position: absolute;

    svg {
      height: 30px;
      width: 30px;
    }

    &.is-prev-day {
      left: 0;
    }

    &.is-next-day {
      right: 0;
    }
  }
`;

export default function HomePageTitle({
  topText,
  title,
  text,
  date,
  onDateChange,
}) {
  const { t } = useTranslation("common");

  function createMarkup() {
    return { __html: text };
  }

  return (
    <StyledHomePageTitle>
      <div
        className="day-change-button is-prev-day"
        onClick={() => onDateChange(date.subtract(1, "day"))}
      >
        <ChevronBackIcon />
      </div>

      <div
        className="day-change-button is-next-day"
        onClick={() => onDateChange(date.add(1, "day"))}
      >
        <ChevronForwardIcon />
      </div>

      <div className="page-title-small animated fadeIn">
        <div className="page-tite-text">{topText}</div>
      </div>
      <div className="page-title-big animated fadeIn">{title}</div>
      {text && (
        <div className="page-title-small animated fadeIn">
          <div
            className="page-tite-text"
            dangerouslySetInnerHTML={createMarkup()}
          ></div>
        </div>
      )}
    </StyledHomePageTitle>
  );
}
