import styled from "styled-components";

export const StyledElements = styled.div`
  .element-wrapper {
    max-width: 580px;
    margin: 0 3% 20px;

    @media (min-width: 720px) {
      margin: 0 auto 20px;
    }
  }

  .icon-select-wrapper {
    margin: 30px 3% 0;
    //display: grid;
    overflow: hidden;
    //grid-template-columns: repeat(3, 1fr);
    //grid-auto-rows: 1fr;
    //grid-column-gap: 3%;
    //grid-row-gap: 0;
    cursor: pointer;
  }

  .icon-select {
    border-radius: 10px;
    background-color: #fff;
    //border: 1px solid #eee;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: all 500ms ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;
    max-width: 580px;
    margin: 0 auto 10px;

    .icon-select-left {
      display: flex;
      align-items: center;
      width: 100%;

      svg {
        width: 40px;
        height: 40px;
        fill: #111;
        transition: all 500ms ease-in-out;
      }

      .icon-select-icon {
        font-size: 30px;
        display: flex;
        align-items: center;
      }
    }

    .icon-select-right {
      svg {
        opacity: 0;
        width: 30px;
        height: 30px;
        fill: #111;
        transition: all 500ms ease-in-out;
      }
    }

    span {
      display: block;
      margin-left: 10px;
      color: #111;
      font-size: 16px;
      font-weight: 500;
      transition: all 500ms ease-in-out;
      text-align: left;
    }

    &.is-active {
      background-color: #222838;

      svg {
        fill: #fff !important;
      }

      .icon-select-right {
        svg {
          opacity: 1;
        }
      }
    }

    &.is-primary-button {
      &.is-active {
        background-color: rgba(193, 156, 123, 0.5);

        span {
          color: #fff;
        }

        svg {
          fill: #fff !important;
        }
      }
    }
  }

  .input-box {
    margin: 0 3%;
    position: relative;

    @media (min-width: 720px) {
      margin: 20px auto 20px;
    }

    &.input-box-single-line {
      display: flex;
      justify-content: space-between;

      &.is-centered {
        justify-content: center;
      }
    }

    .input-box-headline {
      text-align: center;
      margin: 40px 0 10px;
      font-family: "Polarity-Regular", serif;
      font-weight: 700;
      font-size: 16px;
    }

    .input-box-text {
      text-align: center;
      margin-bottom: 20px;
      font-size: 13px;
    }

    .input-box-input-checkbox {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      width: 100%;
      line-height: 1.2;
    }

    .input-box-input-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      width: 100%;
      line-height: 1.2;

      label {
        position: absolute;
        pointer-events: none;
        transform-origin: left center;
        transition: all 250ms;
        display: flex;
        align-items: center;
        //justify-content: center;
        padding-left: 16px;
        text-align: left;
        margin: 0;
        opacity: 0.75;
      }

      select:focus + label,
      select:not(:placeholder-shown) + label,
      input:focus + label,
      input:not(:placeholder-shown) + label {
        transform: translateY(-60%) scale(0.75);
        opacity: 0.25;
      }

      &.is-centered {
      }

      ion-checkbox {
        margin: 0 auto;
      }

      input,
      select {
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 10px;
        padding: 26px 10px 10px 10px;
        text-align: left;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;

        &::placeholder {
          color: transparent;
        }

        // Hide Safari's autofill button
        &::-webkit-contacts-auto-fill-button {
          visibility: hidden;
          pointer-events: none;
          position: absolute;
        }

        &.is-error {
          border-color: #bc3339;
        }
      }

      .birthtime {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .birthtime-hours {
          max-width: 100px;
          margin-right: 10px;
        }

        .birthtime-minutes {
          max-width: 100px;
        }
      }

      input[type="checkbox"] {
        width: 20px !important;
        background-color: #fff !important;
        border: 1px solid #eee !important;
      }

      label {
        width: 100%;
      }

      .birthlocation-entry {
        position: absolute;
        top: 100%;
        height: auto;
        max-height: 200px;
        width: 100%;
        overflow-y: auto;
        border-radius: 10px;

        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
        z-index: 50;
        background-color: #fff;

        .birthlocation-entry-item {
          background-color: #fff;
          border-bottom: 1px solid #eee;
          //border-left: 1px solid #eee;
          //  border-right: 1px solid #eee;
          padding: 10px;
          text-align: left;
          cursor: pointer;
          font-size: 18px;
        }
      }
    }
  }

  .screenshot-watermark {
    position: absolute;
    bottom: 20px;
    left: 3%;
    width: 94%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    visibility: hidden;
    //z-index: 999999;

    .screenshot-watermark-logo {
      width: 60px;
      min-width: 60px;
      overflow: hidden;
      border-radius: 10px;
      height: 60px;
      background-size: cover;
      background-position: 50% 50%;
    }

    .screenshot-watermark-text {
      margin-left: 14px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      text-align: left;

      h4 {
        font-family: "Polarity-Regular", serif;
        font-size: 18px;
        color: #111;
        margin: 0;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 4px;
      }

      span {
        font-size: 14px;
        color: #111;
        line-height: 1.4;
      }
    }
  }

  .story-steps-blocks {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 0 0;
    width: 100%;
    margin: 0 0;
    max-width: 740px;
    position: absolute;
    top: 3%;

    .step-block {
      width: 8px;
      height: 8px;
      overflow: hidden;
      background: linear-gradient(
          to left,
          rgba(193, 156, 123, 0.1) 51%,
          rgba(193, 156, 123, 1) 49%
        )
        right;
      background-size: 201%;
      transition: 0.5s ease-out;

      margin-right: 10px;
      border-radius: 50%;

      .step-progress {
      }

      &.is-done {
        background-position: left;
        .step-progress {
        }
      }
    }
  }

  .next-page {
    position: absolute;
    right: 3%;
    bottom: 4%;
    width: 38px;
    height: 38px;
    border-radius: 10px;
    color: #c19c7b;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      position: relative;
      left: 2px;
      width: 30px;
      height: 30px;
      fill: #c19c7b;
    }
  }

  .prev-page {
    position: absolute;
    left: 3%;
    bottom: 4%;
    width: 38px;
    height: 38px;
    border-radius: 10px;
    color: #c19c7b;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      position: relative;
      left: -2px;
      width: 30px;
      height: 30px;
      fill: #c19c7b;
    }
  }

  .story-button-wrapper {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 20;
    margin-top: 20px;
    position: absolute;
    bottom: 4%;
    width: 100%;

    .story-button-save {
      padding: 6px 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      ion-icon {
        width: 26px;
        height: 26px;
        padding-right: 10px;
        fill: #7f8aa5;
      }
    }

    .story-button-share {
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 16px 6px 10px;
      border-radius: 10px;
      cursor: pointer;
      white-space: nowrap;
      //box-shadow: #cccccc 0 0 16px;
      color: #c19c7b;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 20px;
      background-color: rgba(193, 156, 123, 0.15) !important;
      color: #c19c7b;

      &:first-of-type {
        margin-top: 0;
      }

      svg {
        position: relative;
        top: -2px;
        width: 26px;
        height: 26px;
        padding-right: 10px;
        fill: #c19c7b;
      }
    }
  }

  h6 {
    margin: 0 0 6px;
    font-family: "Assistant", sans-serif;
    font-weight: 700;
  }

  .tag-cloud {
    margin: 10px 0;
    text-align: center;
    max-width: 580px;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      white-space: inherit;
    }

    &.is-left {
      text-align: left;
      margin-left: 3%;

      @media screen and (min-width: 768px) {
        margin: 0 auto;
      }

      .tag {
        margin-left: 0;

        &:first-of-type {
          margin-left: 0 !important;
        }
      }
    }

    .tag {
      padding: 8px 10px;
      border-radius: 10px;
      display: inline-block;
      text-align: center;
      font-size: 14px;
      //background-color: #fff;
      margin: 0 15px 10px 0;
      transition: all 0.3s ease-in-out;
      //box-shadow: #cccccc 0 0 2px;
      background-color: transparent;
      line-height: 1;
      background-color: #fff;
      border: 1px solid whitesmoke;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;
      font-family: "Polarity-Regular", serif;

      &:active {
        background-color: #c19c7b;
        border-color: #c19c7b;
        color: #fff;
      }

      .tag-icon {
        display: inline;
        padding-right: 4px;
      }

      &.is-disabled {
        opacity: 0.5;

        .tag-icon {
          display: none;
        }
      }

      i {
        margin-right: 5px;
      }

      @media screen and (min-width: 768px) {
        font-size: 15px;
      }

      &:first-of-type {
        margin-left: 3%;
      }

      svg {
        width: 20px;
        height: 20px;
        position: relative;
        top: -2px;
        margin-right: 4px;
        fill: #111;
      }

      &.is-active {
        border-color: #c19c7b;
        background-color: #c19c7b;
        color: #fff;

        svg {
          fill: #fff;
        }
      }

      &.is-selected {
        background-color: #111;
        color: #fff;

        svg {
          fill: #fff;
        }
      }

      &.is-red {
        background-color: #fff;
        color: #111;

        svg {
          fill: #111;
        }
      }
    }
  }

  .slick-slider .artwork-card {
    min-width: 400px;
    margin-right: 20px;
  }

  .artwork-card {
    position: relative;
    margin: 0 3% 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    cursor: pointer;
    border: 1px solid #eee;
    height: 260px;
    background-color: #111;
    max-width: 580px;

    @media (min-width: 720px) {
      margin: 20px auto 20px;
    }

    .artwork-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 50%,
          rgb(0, 0, 0, 0.9) 100%
        );
        z-index: 1;
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgb(0, 0, 0, 0.9) 100%
      );
      z-index: 1;
    }

    &.is-tarot {
      height: 360px;

      .artwork-card-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 10%;
        background-repeat: no-repeat;
        z-index: 1;
        background-size: 50% 50%;
      }
    }

    .artwork-card-content {
      position: absolute;
      top: auto;
      bottom: 10px;
      left: 0;
      width: 100%;
      height: 50%;
      padding: 10px;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;

      .artwork-card-icon {
        background-color: #fff;
        padding: 5px 10px;
        font-size: 14px;
        border-radius: 10px;
        color: #111;

        svg {
          width: 20px;
          height: 20px;
          fill: #111;
          position: relative;
          top: -2px;
          margin-right: 4px;
        }
      }

      .artwork-card-headline {
        padding: 10px 10px;
        line-height: 1.2;
        font-size: 26px;
        font-weight: 800;
        font-family: "Recoleta", serif;
        margin: 0 0 1px 0;
        color: #fff;
        text-align: center;
      }

      .artwork-card-text {
        padding: 0 10px 6px;
        font-size: 12px;
        font-weight: 400;
        color: #111;
        display: block;
        z-index: 999;
        color: #fff;
        width: 100%;
        text-align: center;

        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }

  .section-select {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    background-color: #fff;
    padding: 5px;
    border-radius: 10px;
    z-index: 99;
    width: 94%;
    max-width: 580px;
    //box-shadow: #cccccc 0 0 16px;
    margin: 0 3% 20px;

    @media (min-width: 720px) {
      margin: 20px auto 20px;
    }

    .section-select-entry {
      padding: 14px 20px;
      border-radius: 10px;
      width: 100%;
      text-align: center;
      font-size: 15px;
      white-space: nowrap;
      cursor: pointer;
      line-height: 1;
      font-family: "Polarity-Regular", serif;
      font-weight: 600;
      color: #111;

      &.is-active {
        background-color: #c19c7b;
        color: #fff;
      }
    }
  }

  .task-wrapper {
    box-shadow: #cccccc 0 0 16px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    max-width: 580px;

    margin: 0 3% 20px;

    @media (min-width: 720px) {
      margin: 20px auto 0;
    }
  }

  .paper {
    background: #fff;
    padding: 20px;
    position: relative;
    margin: 20px 3% 0;
    max-width: 580px;

    @media (min-width: 720px) {
      margin: 20px auto 0;
    }

    h4 {
      color: #c19c7b;
      font-family: "breathing", sans-serif;
      font-size: 20px;
      line-height: 1.2;
      position: relative;
      top: 10px;
      left: 4px;
      margin-bottom: 20px;
      display: block;
      text-align: center;
    }

    p {
      font-family: now, sans-serif;
      font-size: 14px;
      text-align: center;
      line-height: 1.4;
      margin: 0px auto 10px;
      width: 90%;
      max-width: 420px;
    }
  }

  .paper,
  .paper::before,
  .paper::after {
    box-shadow: #cccccc 0 0 16px;
    border-radius: 10px;
  }

  .paper::before,
  .paper::after {
    content: "";
    position: absolute;
    height: 95%;
    width: 99%;
    background-color: #eee;
  }

  .paper::before {
    right: 15px;
    top: 0;
    transform: rotate(-1deg);
    z-index: -1;
  }

  .paper::after {
    top: 5px;
    right: -5px;
    transform: rotate(1deg);
    z-index: -2;
  }

  .settings {
    margin-bottom: 20px;

    .settings-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0 3%;
      font-size: 15px;
      font-weight: 400;
      font-family: Assistant, sans-serif;
      line-height: 1.4;
      color: #111;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      padding-bottom: 20px;

      &:last-of-type {
        border-bottom: 0 none;
      }

      /* The switch - the box around the slider */
      .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 28px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: #c19c7b;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #c19c7b;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(21px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }

  .footer-button-area {
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.1) 0%,
      rgb(255, 255, 255, 1) 10%
    );
    padding-top: 40px;

    .footer-button-area-inner {
      max-width: 600px;
      margin: 0 auto;
    }

    .is-outline {
      margin: 0 0 20px;
      padding: 0;
      border: 0 none;
    }

    div {
      margin: 0 3% 10px;
    }
  }

  .go-back {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
    transition: all 0.3s;
    color: #c19c7b;

    svg {
      height: 30px;
      width: 30px;
      fill: #c19c7b;
    }

    &:hover {
      transform: rotate(360deg);
    }

    @media (min-width: 720px) {
      width: 60px;
      height: 60px;
      top: 5%;
      left: 5%;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  .onboarding-headline {
    font-weight: 700;
    display: block;
    font-family: "Polarity-Regular", serif;
    font-size: 28px;
    margin: 30px 10% 20px;
    color: #111;
    text-align: center;
    line-height: 1.2;
  }

  .onboarding-smallline {
    font-weight: 700;
    display: block;
    font-family: "Polarity-Regular", serif;
    font-size: 27px;
    margin: 20px 10% 20px;
    color: #111;
    text-align: center;
    line-height: 1.2;
  }

  .onboarding-text {
    font-family: Assistant, sans-serif;
    display: block;
    text-align: center;
    margin: 0 3% 20px;
    max-width: 520px;
    font-size: 18px;
  }

  .onboarding-list {
    list-style-position: outside;
    margin: 0 3% 20px;
  }

  .stats-card-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 3%;
    margin: 20px 0;

    @media (min-width: 720px) {
      padding: 0;
      margin: 20px auto;
    }

    .stats-card {
      padding: 8px 0 8px 8px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;
      background-color: #fff;
      border-radius: 10px;
      cursor: pointer;
      border: 1px solid whitesmoke;
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 49%;
      margin-bottom: 10px;

      .stats-card-image {
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 50%;
        margin-right: 10px;
        background-color: #fff;
        border: 1px solid whitesmoke;
        border-radius: 10px;
      }

      .stats-card-text {
        width: 100%;

        .stats-card-label {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: rgba(18, 18, 18, 0.75);
          margin-bottom: 2px;
        }

        .stats-card-value {
          font-size: 14px;
          margin: 0 0 4px;
          color: #111;
          font-weight: 700;
          line-height: 1.2;
          width: 100%;
          font-family: Assistant, sans-serif;
        }
      }

      .stats-card-icon {
        position: absolute;
        right: -5px;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: 40px;
        min-width: 40px;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        align-items: center;
        display: none;

        svg {
          width: 20px;
          margin: 0 auto;
          display: inline-block;
          fill: rgba(18, 18, 18, 0.75);
          color: rgba(18, 18, 18, 0.75);
        }
      }
    }
  }

  .profile-header-wrapper {
    .profile-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .profile-image {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin: 0 auto 20px;
      }

      .profile-props {
        & > div {
          position: absolute;
          font-size: 12px;
          //background-color: #fff;
          //box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;
          //padding: 10px;
          width: 200px;
          border-radius: 10px;

          strong {
            display: block;
          }
        }

        .profile-props-dominant-element {
          text-align: right;
          top: 0;
          left: -150px;
          transform: translate(50%, 0);
        }

        .profile-props-dominant-modality {
          text-align: right;
          top: 80px;
          left: -150px;
          transform: translate(50%, 0);
        }

        .profile-props-moon-phase {
          text-align: right;
          top: 160px;
          left: -150px;
          transform: translate(50%, 0);
        }

        .profile-props-rising-sign {
          text-align: left;
          top: 0;
          left: 170px;
          transform: translate(50%, 0);
        }

        .profile-props-sun-sign {
          text-align: left;
          top: 80px;
          left: 170px;
          transform: translate(50%, 0);
        }

        .profile-props-moon-sign {
          text-align: left;
          top: 160px;
          left: 170px;
          transform: translate(50%, 0);
        }
      }
    }
  }
`;
