import React from "react";
import Modal from "components/Modal";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";
import MoonCalendar from "pages/MoonCalendar/MoonCalendar";
import AffirmationPage from "pages/AffirmationPage/AffirmationPage";

const ModalManager = React.memo(({ modals }) => {
  return (
    <>
      {modals.onboarding.show && (
        <Modal onClose={modals.onboarding.onClose}>
          <OnboardingPage onClose={modals.onboarding.onClose} />
        </Modal>
      )}
      {modals.moonCalendar.show && (
        <Modal onClose={modals.moonCalendar.onClose}>
          <MoonCalendar />
        </Modal>
      )}
      {modals.affirmation.show && (
        <Modal onClose={modals.affirmation.onClose}>
          <AffirmationPage handleClose={modals.affirmation.onClose} />
        </Modal>
      )}
      {modals.landing.show && (
        <Modal onClose={modals.landing.onClose}>
          <OnboardingPage onClose={modals.landing.onClose} />
        </Modal>
      )}
    </>
  );
});

export default ModalManager;
