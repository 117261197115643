import React, { useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import Lottie from "react-lottie";
import * as animationDataOriginal from "assets/lottie/animation_onboarding_benefits.json";
import styled from "styled-components";

const StyledLoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .loading-animation {
    width: 350px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
  }

  h2 {
    font-family: "Polarity-Regular", serif;
    color: #111;
    max-width: 100%;
    margin: 0 auto 10px;
  }

  span {
    display: block;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.6;
    color: #111;
    max-width: 300px;
  }
`;

export default function LoadingOverlay({ title, text }) {
  const { t } = useTranslation("common");

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationDataOriginal,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });

  return (
    <StyledLoadingOverlay>
      <div className="loading-animation">
        <Lottie
          options={defaultOptions}
          height={300}
          width={300}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>
      <h2>{title}</h2>
      <span>{text}</span>
    </StyledLoadingOverlay>
  );
}
