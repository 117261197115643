import styled from "styled-components";

export const StyledApp = styled.div`
  line-height: 1.4;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: Assistant, sans-serif;
  color: #111;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;

  * {
    box-sizing: border-box;
  }

  @media (min-width: 768px) {
    padding: 0;
  }
`;
