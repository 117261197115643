import ContentCard from "components/ContentCard";
import PageTitle from "components/PageTitle";
import { API_URL } from "constants/URLS";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAPI } from "context/APIcontext";
import SectionHeader from "components/SectionHeader";
import StyledSlider from "components/StyledSlider";
import ContentCardBig from "components/ContentCardBig";
import { shuffleArray } from "helper/shuffleArray";
import TrainingPlanDetail from "./TrainingPlanDetail";
import TrainingPlanStarted from "./TrainingPlanStarted";
import Modal from "components/Modal";

const StyledTrainingPlanListPage = styled.div`
  max-width: 580px;
  margin: 0 3% 20px;
  width: 100%;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }
`;

export default function TrainingPlanList({
  mappedCoachingSessions,
  onClose,
  onPlanStarted,
}) {
  const { t } = useTranslation("common");

  const { isPremium, coachingSessions, trainingPlansOfTheDay } = useAPI();

  const [selectedTrainingPlan, setSelectedTrainingPlan] = useState(null);

  console.log(coachingSessions);

  var sliderSettings = {
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
        },
      },
    ],
  };

  return (
    <StyledTrainingPlanListPage>
      <PageTitle
        title={t("coaching-page.training-plan-list-headline")}
      ></PageTitle>

      <SectionHeader
        isLeft={true}
        title={t(`recommended-courses`)}
      ></SectionHeader>

      <div className="animated fadeIn">
        {trainingPlansOfTheDay && (
          <StyledSlider {...sliderSettings} hasMaxWidth={true}>
            {shuffleArray(trainingPlansOfTheDay)
              .slice(0, 4)
              .map((trainingPlan, index) => (
                <ContentCardBig
                  key={"trainingPlan" + index}
                  title={trainingPlan.title}
                  text={`${trainingPlan.description}`}
                  isLocked={false}
                  image={`${API_URL}/api/images/watercolor/${encodeURIComponent(
                    trainingPlan.title.toLowerCase().replace(/ /g, "_"),
                  )}.jpg`}
                  onClick={() => {
                    setSelectedTrainingPlan(trainingPlan);
                  }}
                ></ContentCardBig>
              ))}
          </StyledSlider>
        )}
      </div>

      <div className="animated fadeIn">
        <SectionHeader isLeft={true} title={t(`discover`)}></SectionHeader>
      </div>

      <div className="animated fadeIn">
        {mappedCoachingSessions &&
          mappedCoachingSessions.training_plans.map((trainingPlan, index) => (
            <>
              <ContentCard
                key={"trainingPlan" + index}
                title={trainingPlan.title}
                text={`${t("course")}: ${
                  trainingPlan.training_modules[0].training_items.length - 2
                } ${t("coaching-page.exercises")}`}
                isLocked={!isPremium}
                image={`${API_URL}/api/images/watercolor/${encodeURIComponent(
                  trainingPlan.title.toLowerCase().replace(/ /g, "_"),
                )}.jpg`}
                onClick={() => {
                  if (!isPremium) {
                    setShowLandingPage(true);
                  } else {
                    setSelectedTrainingPlan(trainingPlan);
                  }
                }}
              ></ContentCard>
            </>
          ))}
      </div>

      {selectedTrainingPlan && (
        <Modal
          onClose={() => {
            setSelectedTrainingPlan(null);
          }}
        >
          <TrainingPlanDetail
            selectedTrainingPlan={selectedTrainingPlan}
            handleClose={() => {
              setSelectedTrainingPlan(null);
            }}
            onPlanStarted={() => {
              onPlanStarted();
            }}
          ></TrainingPlanDetail>
        </Modal>
      )}
    </StyledTrainingPlanListPage>
  );
}
