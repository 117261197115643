import React, { useState, useEffect, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import {
  API_URL,
  ASSETS_URL,
  BASE_URL,
  HOROSCOPE_API,
  HOROSCOPE_EXPLANATIONS_API,
  HOROSCOPE_SHORT_API,
  MOON_DATA_API,
  MOON_EXPLANATIONS_API,
} from "constants/URLS";
import StyledSlider from "components/StyledSlider";
import { StyledHomePage } from "./StyledHomePage";
import { useTranslation } from "react-i18next";
import { getLanguage } from "helper/getLanguage";
import PageTitle from "components/PageTitle";
import * as utc from "dayjs/plugin/utc"; // import plugin
import * as timezone from "dayjs/plugin/timezone"; // import plugin
import SectionHeader from "components/SectionHeader";
import { useAPI } from "context/APIcontext";
import TaskCard from "components/TaskCard";
import TagCloud from "components/TagCloud";
import FriendsCard from "components/FriendsCard";
import BlockButton from "components/buttons/BlockButton";
import {
  TASKS_GO_TO_AFFIRMATION,
  TASKS_GO_TO_TAROT,
  TASK_GO_TO_CHALLENGE,
  TASK_GO_TO_HOROSCOPE,
  TASK_GO_TO_MOONDAY,
  TASK_MARK_AS_DONE,
} from "constants/TASKS";
import AddFriend from "pages/AddFriend/AddFriend";
import Modal from "components/Modal";
import SeeYouTomorrow from "components/SeeYouTomorrow";
import LoadingState from "components/LoadingState";
import HoroscopeDetail from "pages/HorosopeDetail/HoroscopeDetail";
import StoryPage from "pages/StoryPage/StoryPage";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";
import BirthchartPage from "pages/BirthchartPage/BirthchartPage";
import AddFriendsCard from "components/AddFriendCard";
import MoonCalendar from "pages/MoonCalendar/MoonCalendar";
import ContentCard from "components/ContentCard";
import { styled } from "styled-components";
import FriendDetailPage from "pages/FriendDetailPage/FriendDetailPage";
import HomePageTitle from "./HomePageTitle";
import FriendsPage from "../FriendsPage/FriendsPage";
import AstrologyEvents from "components/AstrologyEvents";
import AffirmationPage from "pages/AffirmationPage/AffirmationPage";
import ContentCardLoading from "components/ContentCardLoading";

// Import our new components
import TaskList from "./components/TaskList";
import ModalManager from "./components/ModalManager";
import useModalState from "./components/useModalState";
import { getMoonData, prepareMoonData } from "./components/MoonService";
import {
  fetchAstrologyEvents,
  transformAstrologyEventsToTasks,
} from "./components/AstrologyService";

require("dayjs/locale/de");

dayjs.extend(utc);
dayjs.extend(timezone);

const AspectFilter = styled.div`
  margin: 20px 3%;
  max-width: 580px;

  @media (min-width: 720px) {
    margin: 20px auto;
  }

  select {
    width: 100%;
    padding: 12px;
    border: 1px solid whitesmoke;
    border-radius: 10px;
    background-color: white;
    font-size: 14px;
    font-family: Assistant, sans-serif;
    color: #111;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 12px top 50%;
    background-size: 12px auto;
  }
`;

export default function HomePage({ setCurrentTab }) {
  const { t } = useTranslation("common");
  const {
    userData,
    getTodaysTasks,
    markTaskAsDone,
    setShowChallengeDoneModal,
    userLoggedIn,
    isPremium,
    getMoonExplanation,
    coachingSessions,
  } = useAPI();

  // Modal states
  const [showOnboarding, openOnboarding, closeOnboarding] =
    useModalState(false);
  const [showMoonCalendar, openMoonCalendar, closeMoonCalendar] =
    useModalState(false);
  const [showAffirmationPage, openAffirmationPage, closeAffirmationPage] =
    useModalState(false);
  const [showLandingPage, openLandingPage, closeLandingPage] =
    useModalState(false);

  // App states
  const [moonData, setMoonData] = useState({});
  const [moonExplanation, setMoonExplanation] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedDateString, setSelectedDateString] = useState(
    `${dayjs().format("D")}. ${t(dayjs().format("MMMM").toLowerCase())} ${dayjs().format("YYYY")}`,
  ); // Get current date
  const [moonChangeTime, setMoonChangeTime] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const [moonDoItems, setMoonDoItems] = useState([]);
  const [selectedMoonDoItem, setSelectedMoonDoItem] = useState();
  const [moonDayExplanation, setMoonDayExplanation] = useState();
  const [selectedChallenge, setSelectedChallenge] = useState();
  const [astrologyEvents, setAstrologyEvents] = useState({
    weeklyEvents: [],
    monthlyEvents: [],
    yearlyEvents: [],
  });
  const [selectedAspectFilter, setSelectedAspectFilter] = useState("all");
  const [showAllWeeklyEvents, setShowAllWeeklyEvents] = useState(false);
  const [showAllMonthlyEvents, setShowAllMonthlyEvents] = useState(false);
  const [showAllYearlyEvents, setShowAllYearlyEvents] = useState(false);

  const selectedDateStringMemo = useMemo(
    () =>
      `${dayjs(selectedDate).format("D")}. ${t(dayjs(selectedDate).format("MMMM").toLowerCase())} ${dayjs(selectedDate).format("YYYY")}`,
    [selectedDate, t],
  );

  const moonChangeTimeMemo = useMemo(
    () =>
      moonData.zodiacChangeTimeUTC
        ? dayjs
            .utc(moonData.zodiacChangeTimeUTC)
            .tz(dayjs.tz.guess())
            .format("HH:mm")
        : "",
    [moonData.zodiacChangeTimeUTC],
  );

  const handleSelectDate = useCallback((newDate) => {
    setSelectedDate(newDate);
    setSelectedDateString(
      `${dayjs(newDate).format("D")}. ${t(dayjs(newDate).format("MMMM").toLowerCase())} ${dayjs(newDate).format("YYYY")}`,
    );
  }, []);

  const handleTask = useCallback(
    (task, taskIndex) => {
      const taskActions = {
        [TASK_MARK_AS_DONE]: () => {
          markTaskAsDone(taskIndex);
          refreshToday();
        },
        [TASK_GO_TO_HOROSCOPE]: () => {
          if (userLoggedIn) {
            goToHoroscope();
            if (userData.userProfile) {
              markTaskAsDone(taskIndex);
              refreshToday();
            }
          } else {
            openOnboarding();
          }
        },
        [TASKS_GO_TO_AFFIRMATION]: () => {
          openAffirmationPage();
          markTaskAsDone(taskIndex);
          refreshToday();
        },
        [TASKS_GO_TO_TAROT]: () => {
          setCurrentTab("tarot");
          markTaskAsDone(taskIndex);
          refreshToday();
        },
        [TASK_GO_TO_MOONDAY]: () => {
          moonExplanation[0].title = `${t("moon-in")} ${t(moonData.zodiac[0])}`;
          setMoonDayExplanation(moonExplanation[0]);
          markTaskAsDone(taskIndex);
          refreshToday();
        },
        [TASK_GO_TO_CHALLENGE]: () => {
          if (userLoggedIn) {
            setSelectedChallenge(task);
            refreshToday();
          } else {
            openOnboarding();
          }
        },
      };

      const action = taskActions[task.action];
      if (action) {
        action();
      }
    },
    [userLoggedIn, userData, moonData, moonExplanation, t],
  );

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!userData?.userProfile || !coachingSessions) return;

      try {
        setIsLoading(true);
        const responseMoonData = await getMoonData(selectedDate, getLanguage());
        const tasks = await getTodaysTasks(
          coachingSessions,
          responseMoonData,
          t,
        );
        const responseMoonExplanation = await getMoonExplanation(
          responseMoonData.moonDay,
          responseMoonData.zodiac[0],
          userData.zodiacSign || "none",
          responseMoonData.phase,
        );

        if (!isMounted) return;

        const { moonChangeTime, moonDoItems, selectedMoonDoItem } =
          prepareMoonData(responseMoonData, responseMoonExplanation);

        setTaskList(tasks);
        setMoonData(responseMoonData);
        setMoonChangeTime(moonChangeTime);
        setMoonExplanation(responseMoonExplanation);
        setMoonDoItems(moonDoItems);
        setSelectedMoonDoItem(selectedMoonDoItem);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [selectedDate, userData, isPremium, userLoggedIn, coachingSessions]);

  const refreshToday = useCallback(() => {
    getTodaysTasks(moonData, t).then((tasks) => {
      setTaskList(tasks);
      setIsLoading(false);
    });
  }, [moonData, t, getTodaysTasks]);

  const goToHoroscope = useCallback(
    (timeframe = "today") => {
      if (userData.userProfile) {
        setShowHoroscopeDetail({
          selectedUser: userData.userProfile,
          show: true,
          timeframe: timeframe,
        });
      } else {
        openOnboarding();
      }
    },
    [userData, openOnboarding],
  );

  const goToMoonDetailPagePage = useCallback(() => {
    moonExplanation[0].title = `${t("moon-in")} ${t(moonData.zodiac[0])}`;
    setMoonDayExplanation(moonExplanation[0]);
  }, [moonExplanation, moonData, t]);

  const getTransits = async (userData) => {
    const birthChartAPI = `${HOROSCOPE_API}?language=${getLanguage()}&birthDay=${
      userData.birthDay
    }&birthMonth=${userData.birthMonth}&birthYear=${userData.birthYear}&birthHour=${
      userData.birthHour
    }&birthMinute=${userData.birthMinute}&latitude_degrees=${
      userData.location.latitude_degrees
    }&latitude_direction=${userData.location.latitude_direction}&latitude_minutes=${
      userData.location.latitude_minutes
    }&longitude_degrees=${userData.location.longitude_degrees}&longitude_direction=${
      userData.location.longitude_direction
    }&longitude_minutes=${userData.location.longitude_minutes}&city=${
      userData.location.city
    }&country_short=${userData.location.country_short}&region=${
      userData.location.region
    }&day=${selectedDate.format("D")}&month=${selectedDate.format("M")}&year=${selectedDate.format(
      "YYYY",
    )}`;

    const response = await fetch(birthChartAPI);
    const data = await response.json();
    return data;
  };

  const getShortHoroscopeForSelectedDate = async (titles) => {
    try {
      const language = getLanguage();
      const requests = titles.map(async (title) => {
        const brithChartAPI = `${HOROSCOPE_SHORT_API}?titles[]=${encodeURIComponent(
          title,
        )}&language=${language}`;
        const response = await fetch(brithChartAPI);
        return response.json();
      });
      const responses = await Promise.all(requests);
      const mergedArray = responses.reduce((acc, val) => acc.concat(val), []);
      return mergedArray;
    } catch (error) {
      console.error("Error fetching horoscope explanations:", error);
    }
  };

  const aspectFilters = {
    all: { label: "All Aspects", angles: [] },
    conjunctions: { label: "0° Conjunctions", angles: [0] },
    major: {
      label: "Any Major (0°, 60°, 90°, 120°, 180°)",
      angles: [0, 60, 90, 120, 180],
    },
    minor: {
      label: "Major + Minor (+ 135°, 150°)",
      angles: [0, 60, 90, 120, 135, 150, 180],
    },
    hard: { label: "Hard Only (0°, 90°, 180°)", angles: [0, 90, 180] },
    opposition: {
      label: "Conjunctions + Oppositions (0°, 180°)",
      angles: [0, 180],
    },
  };

  return (
    <StyledHomePage>
      {moonData && moonData.image && (
        <div className="moon-wrapper animated fadeIn">
          <img
            src={`${ASSETS_URL}/img/moon/${moonData.image}.webp`}
            alt={`${t(moonData.phase)}`}
            loading="lazy"
          />
        </div>
      )}
      <HomePageTitle
        date={selectedDate}
        onDateChange={handleSelectDate}
        topText={
          selectedDate.isSame(dayjs(), "day")
            ? t("today")
            : selectedDate.format("DD.MM.YYYY")
        }
        title={t(moonData.phase)}
        text={
          moonData && moonData.phase
            ? ` ${
                moonChangeTimeMemo != null
                  ? `${t("moon-in")} ${t(moonData.zodiac[0])}` //${t('at')} ${moonChangeTime}
                  : `${t("moon-in")} ${t(moonData.zodiac[0])}`
              } `
            : ""
        }
      />

      <div className="element-wrapper animated fadeIn">
        <BlockButton
          noMargin
          isOutlined
          noBorder
          isSmall
          onClick={openMoonCalendar}
          text={t("to-moon-calendar")}
        ></BlockButton>
      </div>

      <div className="animated fadeIn">
        <SectionHeader
          noSpacing
          title={t("home.todays-tasks")}
          text={t("home.todays-tasks-text")}
        ></SectionHeader>
        <div>
          <TaskList
            tasks={taskList}
            onTaskClick={handleTask}
            isLoading={isLoading}
            selectedDate={selectedDate}
            userLoggedIn={userLoggedIn}
          />

          {selectedDate.format("YYYY-MM-DD") !==
            dayjs().format("YYYY-MM-DD") && (
            <>
              <ContentCard
                title={`${t(moonData.phase)}: ${t(moonData.zodiac[0])}`}
                text={`${moonData.moonDay}. ${t("tasks.moonday-headline")}`}
                image={`${API_URL}/api/images/watercolor/zodiac-${moonData.zodiac[0]
                  .toLowerCase()
                  .replace(/ /g, "_")}.jpg`}
                onClick={() => {
                  goToMoonDetailPagePage();
                }}
                isDone={false}
                isTask
              ></ContentCard>
              <ContentCard
                title={t("tasks.todays-horoscope")}
                text={t("tasks.todays-horoscope-text")}
                image={`${API_URL}/api/images/watercolor/horoscope-${selectedDate.format(
                  "dddd",
                )}.jpg`}
                onClick={() => {
                  goToHoroscope();
                }}
                isDone={false}
                isTask
                isLocked={!userLoggedIn && !isPremium}
              ></ContentCard>
            </>
          )}
        </div>

        <SectionHeader
          title={t("home.your-week")}
          text={t("home.your-week-text")}
        ></SectionHeader>

        <div>
          <ContentCard
            image={`${API_URL}/api/images/watercolor/weekly-horoscope.jpg`}
            title={t("weekly-horoscope-headline")}
            text={t("weekly-horoscope-subheadline")}
            onClick={() =>
              userLoggedIn && isPremium
                ? goToHoroscope("week")
                : openOnboarding()
            }
            isLocked={!userLoggedIn || !isPremium}
          />
        </div>

        <SectionHeader
          title={t("home.your-month")}
          text={t("home.your-month-text")}
        ></SectionHeader>

        <div>
          <ContentCard
            image={`${API_URL}/api/images/watercolor/monthly-horoscope.jpg`}
            title={t("monthly-horoscope-headline")}
            text={t("monthly-horoscope-subheadline")}
            onClick={() =>
              userLoggedIn && isPremium
                ? goToHoroscope("month")
                : openOnboarding()
            }
            isLocked={!userLoggedIn || !isPremium}
          />
          <ContentCard
            image={`${API_URL}/api/images/watercolor/moon-phase-calendar.jpg`}
            title={`${t("moon-phase-calendar-headline")}`}
            text={`${t("moon-phase-calendar-subheadline")}`}
            onClick={() => {
              openMoonCalendar();
            }}
          ></ContentCard>
        </div>

        <SectionHeader
          title={t("home.your-year")}
          text={t("home.your-year-text")}
        ></SectionHeader>

        <div>
          <ContentCard
            image={`${API_URL}/api/images/watercolor/solar-return-horoscope.jpg`}
            title={t("solar-return-headline")}
            text={t("solar-return-subheadline")}
            onClick={() =>
              userLoggedIn && isPremium
                ? setShowBirthChartDetail(userData.userProfile)
                : openOnboarding()
            }
            isLocked={!userLoggedIn || !isPremium}
          />
          {(showAllYearlyEvents
            ? astrologyEvents.yearlyEvents
            : astrologyEvents.yearlyEvents.slice(0, 3)
          ).map((event, index) => (
            <ContentCard
              key={`yearly-event-${index}`}
              title={event.title}
              text={event.intro}
              topic={event.topic}
              image={`${API_URL}/api/images/watercolor/astrology.jpg`}
              isDone={event.isDone}
              isTask={false}
            />
          ))}
          {astrologyEvents.yearlyEvents.length > 3 && (
            <BlockButton
              noMargin
              isOutlined
              noBorder
              onClick={() => setShowAllYearlyEvents(!showAllYearlyEvents)}
              text={showAllYearlyEvents ? t("show-less") : t("show-more")}
            />
          )}
        </div>

        <SectionHeader
          title={t("home.your-life")}
          text={t("home.your-life-text")}
        ></SectionHeader>

        <div>
          <ContentCard
            image={`${API_URL}/api/images/watercolor/my-birthchart.jpg`}
            title={`${t("birthchart-headline")}`}
            text={`${t("birthchart-subheadline")}`}
            onClick={() => {
              if (userLoggedIn && isPremium) {
                setShowBirthChartDetail(userData.userProfile);
              } else {
                openOnboarding();
              }
            }}
            isLocked={!userLoggedIn || !isPremium}
          ></ContentCard>

          <ContentCard
            image={`${API_URL}/api/images/watercolor/human-design.jpg`}
            title={`${t("human-design-headline")}`}
            text={`${t("human-design-subheadline")}`}
            onClick={() => {
              if (userLoggedIn && isPremium) {
                setShowBirthChartDetail(userData.userProfile);
              } else {
                openOnboarding();
              }
            }}
            isLocked={!userLoggedIn || !isPremium}
          ></ContentCard>
        </div>

        <AstrologyEvents date={selectedDate} />

        <SeeYouTomorrow />
      </div>

      {selectedChallenge && (
        <Modal
          onClose={() => {
            setSelectedChallenge(null);
          }}
        >
          <StoryPage
            title={selectedChallenge.content.title}
            text={selectedChallenge.content.text}
            handleClose={() => {
              setSelectedChallenge(null);
            }}
            onChallengeCompleted={() => {
              const challengeIndex = taskList.findIndex(
                (task) => task.action === "TASK_GO_TO_CHALLENGE",
              );
              markTaskAsDone(challengeIndex);
              setSelectedChallenge(null);
              setShowChallengeDoneModal(true);
            }}
          ></StoryPage>
        </Modal>
      )}

      {moonDayExplanation && (
        <Modal
          onClose={() => {
            setMoonDayExplanation(null);
          }}
        >
          <StoryPage
            title={moonDayExplanation.title}
            text={moonDayExplanation.text}
            handleClose={() => {
              setMoonDayExplanation(null);
            }}
          ></StoryPage>
        </Modal>
      )}

      <ModalManager
        modals={{
          onboarding: { show: showOnboarding, onClose: closeOnboarding },
          moonCalendar: { show: showMoonCalendar, onClose: closeMoonCalendar },
          affirmation: {
            show: showAffirmationPage,
            onClose: closeAffirmationPage,
          },
          landing: { show: showLandingPage, onClose: closeLandingPage },
        }}
      />
    </StyledHomePage>
  );
}
